// src/pages/SourcesAdminPage.tsx
import React, { useCallback, useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, limit, QueryDocumentSnapshot, startAfter } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import { Source } from '../types/Sources';
import moment from 'moment';
import ActivityIndicator from '../components/ActivityIndicator';
import SourceItem from '../components/SourceItem';
import { renderSpecialCharacters } from '../utils/general';

const SOURCES_PER_PAGE = 25;

const SourcesAdminPage: React.FC = () => {
  const [sources, setSources] = useState<Source[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot | null>(null);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    document.title = 'Sources Admin';
    fetchSources();
  }, []);

  const fetchSources = useCallback(async (lastDocument: QueryDocumentSnapshot | null = null) => {
    try {
      let q = query(
        collection(db, 'sources'),
        orderBy('publishedDate', 'desc'),
        limit(SOURCES_PER_PAGE)
      );

      if (lastDocument) {
        q = query(q, startAfter(lastDocument));
      }

      const querySnapshot = await getDocs(q);
      const fetchedSources: Source[] = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Source));

      setSources(prev => lastDocument ? [...prev, ...fetchedSources] : fetchedSources);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
      setHasMore(querySnapshot.docs.length === SOURCES_PER_PAGE);
    } catch (error) {
      console.error('Error fetching sources: ', error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  }, []);

  const loadMore = async () => {
    if (hasMore && !loadingMore) {
      setLoadingMore(true);
      await fetchSources(lastDoc);
    }
  };

  if (loading) {
    return (
      <div className='center-container'>
        <ActivityIndicator />
      </div>
    );
  }

  return (
    <div className='main-container'>
      {sources.map((source) => (
        <div key={source.id} className="source-item">
          <SourceItem key={source.id} source={source} />
          <h2>{renderSpecialCharacters(source.title)}</h2>
          <div className='flex-gap-small'>
            <div>
              <strong>Genre:</strong> {source.genre || 'Not specified'}
            </div>
            <div>
              <strong>Description:</strong> {renderSpecialCharacters(source.description)}
            </div>
            <div>
              <strong>Subjects:</strong> {source.subjects?.join(', ')}
            </div>
            <div>
              <strong>Topics:</strong> {source.topics?.join(', ')}
            </div>
            <div>
              <strong>Published Date:</strong> {moment(source.publishedDate).format('LL')}
            </div>
            <div>
              <strong>URL:</strong> <a href={source.url} target="_blank" rel="noopener noreferrer">{source.url}</a>
            </div>
            <div>
              <strong>Status:</strong> {source.status}
            </div>
            <div>
              <strong>Document ID:</strong> {source.id}
            </div>
            <hr />
          </div>
        </div>
      ))}
      {hasMore && (
        <button 
          onClick={loadMore} 
          disabled={loadingMore}
          className="load-more-button"
        >
        {loadingMore ? 'Loading...' : 'Load More'}
        </button>
      )}
    </div>
  );
};

export default SourcesAdminPage;
