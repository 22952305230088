// src/components/HeadlinesSection.tsx
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TopNewsItem } from './TopNews';
import FeatureSource from './FeatureSource';
import TopNews from './TopNews';
import { fetchContentFromAlgolia } from '../utils/searchUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import ActivityIndicator from './ActivityIndicator';
import { useCountry } from '../contexts/CountryContext';
import Flag from 'react-world-flags';
import { getCountryCode } from '../utils/countryUtils';

const HEADLINES_LIMIT = 50;
const COUNTRY_NEWS_LIMIT = 25;
const COUNTRY_NEWS_PER_PAGE = 1;
const GLOBAL_HEADLINES_PER_PAGE = 3;

const HeadlinesSection: React.FC = () => {
  const navigate = useNavigate();
  const [headlines, setHeadlines] = useState<TopNewsItem[]>([]);
  const [countryNews, setCountryNews] = useState<TopNewsItem[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const initialFetchDone = useRef(false);
  const { selectedCountry } = useCountry();
  const countryCode = selectedCountry ? getCountryCode(selectedCountry) : null;

  const fetchHeadlines = useCallback(async (page: number) => {
    const { headlines } = await fetchContentFromAlgolia(page, HEADLINES_LIMIT, 'news' as 'article' | 'source' | 'news');
    return headlines;
  }, []);

  const fetchCountryNews = useCallback(async (countryName: string) => {
    try {
      const { headlines } = await fetchContentFromAlgolia(0, COUNTRY_NEWS_LIMIT, 'news', [countryName]);
      setCountryNews(headlines);
    } catch (error) {
      console.error(`Error fetching ${countryName} news:`, error);
    }
  }, []);

  useEffect(() => {
    const fetchInitialHeadlines = async () => {
      if (initialFetchDone.current) return;
      setIsLoading(true);
      
      try {
        const initialHeadlines = await fetchHeadlines(0);
        setHeadlines(initialHeadlines);
      } catch (error) {
        console.error('Error fetching initial headlines:', error);
      } finally {
        initialFetchDone.current = true;
        setIsLoading(false);
      }
    };
  
    fetchInitialHeadlines();
  }, [fetchHeadlines]);

  useEffect(() => {
    if (selectedCountry) {
      fetchCountryNews(selectedCountry);
    }
  }, [selectedCountry, fetchCountryNews]);

  const loadMoreHeadlines = useCallback(async () => {
    if (isLoadingMore) return;
    setIsLoadingMore(true);
    try {
      const newHeadlines = await fetchHeadlines(Math.floor(headlines.length / HEADLINES_LIMIT));
      setHeadlines(prev => [...prev, ...newHeadlines]);
    } catch (error) {
      console.error('Error loading more headlines:', error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [fetchHeadlines, headlines.length, isLoadingMore]);

  const goToPreviousPage = useCallback(() => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentPage(prev => Math.max(0, prev - 1));
      setIsTransitioning(false);
    }, 300);
  }, []);

  const goToNextPage = useCallback(async () => {
    setIsTransitioning(true);
    const nextPage = currentPage + 1;
    const requiredHeadlines = (nextPage + 1) * GLOBAL_HEADLINES_PER_PAGE;
    if (requiredHeadlines > headlines.length) {
      await loadMoreHeadlines();
    }
    setTimeout(() => {
      setCurrentPage(nextPage);
      setIsTransitioning(false);
    }, 300);
  }, [currentPage, headlines.length, loadMoreHeadlines]);

  if (isLoading) {
    return <div><ActivityIndicator /></div>;
  }

  if (headlines.length === 0) {
    return <div>No headlines available at the moment.</div>;
  }

  const startIndexGlobal = currentPage * GLOBAL_HEADLINES_PER_PAGE;
  const currentGlobalHeadlines = headlines.slice(startIndexGlobal, startIndexGlobal + GLOBAL_HEADLINES_PER_PAGE);
  const [featureItem, ...restGlobalItems] = currentGlobalHeadlines;

  const startIndexCountry = currentPage * COUNTRY_NEWS_PER_PAGE;
  const currentCountryNews = countryNews.slice(startIndexCountry, startIndexCountry + COUNTRY_NEWS_PER_PAGE);

  const hasMorePages = (currentPage + 1) * GLOBAL_HEADLINES_PER_PAGE < headlines.length ||
                       (currentPage + 1) * COUNTRY_NEWS_PER_PAGE < countryNews.length;

  const handleCountryHeadlinesClick = () => {
    navigate(`/headlines?country=${selectedCountry}`);
  };

  return (
    <>
      <div className="headlines-section">
        <button 
          onClick={goToPreviousPage} 
          className="caret-button left-caret" 
          aria-label="Previous headlines"
          disabled={currentPage === 0}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>
        
        <div className="headlines-container">
          <div className='list-column flex-gap-small'>
            <span className='home-section-header link-pointer' onClick={() => navigate('/headlines')}>Headlines</span>
            <div className='flex-gap flex-gap-small visible-on-laptop'>
              <div className='h3' onClick={handleCountryHeadlinesClick} style={{ cursor: 'pointer' }}>
                {selectedCountry} Headlines
                {countryCode && (
                  <Flag
                    code={countryCode}
                    height="16"
                    width="24"
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </div>
              <div className={`country-news-content ${isTransitioning ? 'fade-out' : 'fade-in'}`}>
                <TopNews 
                  itemSets={[
                    {
                      title: `${selectedCountry} News`,
                      items: currentCountryNews,
                      totalCount: countryNews.length,
                      showExpand: true
                    }
                  ]}
                  onExpand={() => {}}
                  isBreaking={false}
                  useExpandLine={true}
                  expandNavigateTo={`/headlines?country=${selectedCountry}`}
                  showSourceImages={true}
                />
              </div>
            </div>
          </div>
          <div className="feature-column flex-gap-small">
            <div className='h3'>Global Headlines</div>
            <div className={`feature-content ${isTransitioning ? 'fade-out' : 'fade-in'}`}>
              {featureItem && featureItem.type === 'source' && (
                <FeatureSource source={featureItem.content} />
              )}
            </div>
          </div>
          <div className="list-column flex-gap-small">
            <div className='h3 visible-on-laptop'>Global Headlines</div>
            <div className={`list-content ${isTransitioning ? 'fade-out' : 'fade-in'}`}>
              <TopNews 
                itemSets={[{ 
                  title: 'Headlines',
                  items: restGlobalItems,
                  totalCount: headlines.length,
                  showExpand: headlines.length > GLOBAL_HEADLINES_PER_PAGE
                }]}
                onExpand={() => {}}
                isBreaking={false}
                useExpandLine={true}
                expandNavigateTo="/headlines"
              />
            </div>
            <div className='flex-gap-small visible-on-mobile'>
              <div className='h3' onClick={handleCountryHeadlinesClick} style={{ marginBottom: 'var(--flex-gap-small)' }}>
                {selectedCountry} Headlines
                {countryCode && (
                  <Flag
                    code={countryCode}
                    height="16"
                    width="24"
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </div>
              <div className={`country-news-content ${isTransitioning ? 'fade-out' : 'fade-in'}`}>
                <TopNews 
                  itemSets={[
                    {
                      title: `${selectedCountry} News`,
                      items: currentCountryNews,
                      totalCount: countryNews.length,
                      showExpand: true
                    }
                  ]}
                  onExpand={() => {}}
                  isBreaking={false}
                  useExpandLine={true}
                  expandNavigateTo={`/headlines?country=${selectedCountry}`}
                  showSourceImages={true}
                />
              </div>
            </div>
          </div>
        </div>
        
        <button 
          onClick={goToNextPage} 
          className="caret-button right-caret" 
          aria-label="Next headlines"
          disabled={!hasMorePages}
        >
          <FontAwesomeIcon icon={faCaretRight} />
        </button>
      </div>
    </>
  );
};

export default HeadlinesSection;
