// src/pages/ConfirmNewAccountVerificationPage.tsx
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import ActivityIndicator from '../components/ActivityIndicator';
import { getAuth } from 'firebase/auth';

const ConfirmNewAccountVerificationPage: React.FC = () => {
  const [verificationStatus, setVerificationStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const ConfirmNewAccountVerificationPage = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');

      if (!token) {
        setVerificationStatus('error');
        return;
      }

      try {
        const response = await fetch(`https://${process.env.REACT_APP_REGION}-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/confirmNewAccountVerification`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          console.error('Verification failed:', await response.text());
          setVerificationStatus('error');
          return;
        }

        // Set verificationStatus to 'success' immediately upon successful response
        setVerificationStatus('success');

        // Optional: Listen to emailVerificationTokens/{uid}/isVerified
        // This can be removed if not needed
        const uid = auth.currentUser?.uid;
        if (uid) {
          const firestore = getFirestore();
          const tokenDocRef = doc(firestore, `emailVerificationTokens/${uid}`);

          const unsubscribe = onSnapshot(tokenDocRef, (docSnapshot) => {
            const tokenData = docSnapshot.data();
            if (tokenData?.isVerified) {
              // Delay navigation to allow user to read success message
              setTimeout(() => navigate('/'), 3000);
            }
          });

          return () => unsubscribe();
        } else {
          // Log a message that the user is not logged in
          console.warn('User is not logged in. Verification process completed successfully.');
        }

      } catch (error) {
        console.error('Error verifying email:', error);
        if (error instanceof TypeError && error.message === 'Failed to fetch') {
          console.error('Network error: Could not reach the server. Please check your internet connection and try again.');
        }
        setVerificationStatus('error');
      }
    };

    ConfirmNewAccountVerificationPage();
  }, [location, navigate, auth]);

  return (
    <div className="main-container">
      <div className="verify-email-container">
        {verificationStatus === 'loading' && (
          <>
            <div className='h2'>Verifying your email...</div>
            <div className='center-align'><ActivityIndicator /></div>
          </>
        )}
        {verificationStatus === 'success' && (
          <>
            <div className='h2'>Email verified successfully!</div>
            <p>Return to your opened CyberHeadliners page, or <Link to={'/'}>open in this window</Link>.</p>
          </>
        )}
        {verificationStatus === 'error' && (
          <>
            <div className='h2'>Verification failed</div>
            <p>There was an error verifying your email. Please try again or contact support.</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmNewAccountVerificationPage;
