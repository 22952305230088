// src/components/InputFields.tsx
import React, { useEffect, useState } from 'react';

const InputFields = () => {
  const [topic, setTopic] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [style, setStyle] = useState<string>('');

  useEffect(() => {
    const storedTopic = sessionStorage.getItem('write-topic');
    const storedType = sessionStorage.getItem('write-type');
    const storedStyle = sessionStorage.getItem('write-style');

    if (storedTopic) setTopic(storedTopic);
    if (storedType) setType(storedType);
    if (storedStyle) setStyle(storedStyle);
  }, []);

  return (
    <div>
      <input
        id='write-topic'
        placeholder='Topic (e.g. incidence response, artificial intelligence)'
        value={topic}
        onChange={(e) => setTopic(e.target.value)}
        required
      />
      <input
        id='write-type'
        placeholder='Article Type (e.g. thought leadership, how-tos)'
        value={type}
        onChange={(e) => setType(e.target.value)}
        required
      />
      <input
        id='write-style'
        placeholder='Style (e.g. efficient, easy-to-read, informative and confident)'
        value={style}
        onChange={(e) => setStyle(e.target.value)}
        required
      />
    </div>
  );
};

export default InputFields;
