// src/components/ArticleEditor.tsx
import React, { useEffect, useState } from 'react';
import ActivityIndicator from './ActivityIndicator';
import EditorJS from '@editorjs/editorjs';
import Paragraph from '@editorjs/paragraph';
import Header from '@editorjs/header';
import { Article, Section } from '../types/Articles';
import '../styles/ReferenceArticleSelector.css';
import { generateArticlePrompt } from '../utils/generateArticlePrompt';

interface ArticleEditorProps {
  selectedArticles: Article[];
  writeSample: string;
}

const ArticleEditor: React.FC<ArticleEditorProps> = ({ selectedArticles, writeSample }) => {
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [article, setGeneratedArticle] = useState<Article | null>(null);
  const [buttonText, setButtonText] = useState<string>('Write Article');

  useEffect(() => {
    const storedArticle = sessionStorage.getItem('article');
    if (storedArticle) {
      const parsedArticle: Article = JSON.parse(storedArticle);
      setGeneratedArticle(parsedArticle);
      setButtonText('Rewrite Article');
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const writeTopic = (document.getElementById('write-topic') as HTMLInputElement).value;
    const writeType = (document.getElementById('write-type') as HTMLInputElement).value;
    const writeStyle = (document.getElementById('write-style') as HTMLInputElement).value;

    sessionStorage.setItem('write-topic', writeTopic);
    sessionStorage.setItem('write-type', writeType);
    sessionStorage.setItem('write-style', writeStyle);

    let prompt = await generateArticlePrompt(selectedArticles, writeSample);
    await generateArticle(prompt);
  };

  const generateArticle = async (prompt: string) => {
    setIsGenerating(true);

    let attempts = 0;
    let success = false;

    while (attempts < 3 && !success) {
      try {
        const response = await fetch('https://asia-southeast1-cyber-security-incidences.cloudfunctions.net/generateArticle', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ prompt })
        });

        if (!response.ok) {
          throw new Error(`Failed to generate article, status: ${response.status}`);
        }

        let text = await response.text();
        text = text.replace(/```json/g, '').replace(/```/g, '').trim();

        try {
          const json = JSON.parse(text);
          console.log(json);
          setGeneratedArticle(json);
          sessionStorage.setItem('article', JSON.stringify(json)); // Save to sessionStorage
          setButtonText('Rewrite Article');
          success = true; // Set success flag to true if parsing succeeds
        } catch (parseError) {
          console.error('Failed to parse JSON:', parseError);
          console.error('Raw response:', text);
          setGeneratedArticle(null);
        }
      } catch (error) {
        console.error('Failed to fetch article:', error);
        setGeneratedArticle(null);
      }

      attempts++;
    }

    setIsGenerating(false);

    if (!success) {
      console.error('Failed to generate and parse article after 3 attempts');
      alert('Failed to generate and parse article. Please try again later.');
    }
  };

  const initializeEditor = (article: Article) => {
    if (!article.sections) {
      console.error('No sections found in the generated article');
      return;
    }

    new EditorJS({
      holder: 'editorjs',
      tools: {
        paragraph: Paragraph,
        header: Header,
      },
      data: {
        time: new Date().getTime(),
        blocks: [
          {
            type: 'header',
            data: {
              text: article.title,
              level: 1,
            },
          },
          {
            type: 'paragraph',
            data: {
              text: article.synopsis,
            },
          },
          ...Object.values(article.sections).flatMap((section: Section) => [
            {
              type: 'header',
              data: {
                text: section.header,
                level: 2,
              },
            },
            ...section.paragraphs.map((paragraph: string) => ({
              type: 'paragraph',
              data: { text: paragraph },
            })),
          ]),
        ],
      },
    });
  };

  useEffect(() => {
    if (article) {
      initializeEditor(article);
    }
  }, [article]);

  return (
    <div className='flex-gap-medium'>
      <form onSubmit={handleSubmit}>
        <button className='full' type='submit' disabled={isGenerating}>{buttonText}</button>
      </form>
      <div>
        {isGenerating ? (
          <div className='flex-gap' style={{ alignItems: 'center' }}>
            <div>Writing article now. Please wait...</div>
            <div><ActivityIndicator /></div>
          </div>
        ) : (
          <div id='generated-article'>
            {article ? (
              <div id='editorjs'></div>
            ) : 'Input your requirements and click on "Write Article" when ready. Your generated article will appear here.'}
          </div>
        )}
      </div>
      {!isGenerating && article && (
        <button className='full'>Publish Article</button>
      )}
    </div>
  );
};

export default ArticleEditor;
