// src/components/CreateAccountForm.tsx
import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../utils/firebaseConfig';
import ReCAPTCHA from 'react-google-recaptcha';
import { useReCaptcha } from '../contexts/ReCaptchaContext';
import EmailVerification from './EmailVerification';
import ActivityIndicator from './ActivityIndicator';

interface CreateAccountFormProps {
  onSuccess: () => void;
  onSwitchToLogin: () => void;
}

const CreateAccountForm: React.FC<CreateAccountFormProps> = ({ onSuccess, onSwitchToLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const { reCaptchaKey } = useReCaptcha();
  const [error, setError] = useState('');
  const [accountCreated, setAccountCreated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCreateAccount = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    if (!validateInputs()) {
      setIsSubmitting(false);
      return;
    }

    try {
      if (!captchaValue) {
        throw new Error('Please complete the reCAPTCHA');
      }
  
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;
      
      // Send custom verification email
      const response = await fetch('https://asia-southeast1-cyber-security-incidences.cloudfunctions.net/sendNewAccountVerification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, uid: uid }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send verification email');
      }
  
      setAccountCreated(true);
    } catch (error: any) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateInputs = () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return false;
    }

    if (!agreeToTerms) {
      setError('You must agree to the Terms of Service and Privacy Policy');
      return false;
    }

    if (!captchaValue) {
      setError('Please complete the reCAPTCHA');
      return false;
    }

    return true;
  };

  const handleError = (error: any) => {
    switch(error.code) {
      case 'auth/email-already-in-use':
        setError('This email is already in use');
        break;
      case 'auth/invalid-email':
        setError('Invalid email address');
        break;
      default:
        setError('An error occurred. Please try again.');
    }
  };

  if (accountCreated) {
    return <EmailVerification onVerified={onSuccess} />;
  }
  
  return (
    <form onSubmit={handleCreateAccount} className='form-container'>
      <div className='h4'>Create Account</div>
      <input
        type="email"
        placeholder="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Confirm Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
      />
      <div>
        <label className='flex-gap-row flex-gap-small smaller'>
          <input
            type="checkbox"
            checked={agreeToTerms}
            onChange={(e) => setAgreeToTerms(e.target.checked)}
            style={{ flex: 1 }}
            required
          />
          <span>I agree to the Terms of Service and Privacy Policy</span>
        </label>
      </div>
      <div style={{ margin: 'auto' }}>
        {reCaptchaKey && (
          <ReCAPTCHA
            sitekey={reCaptchaKey}
            onChange={(value) => setCaptchaValue(value)}
          />
        )}
      </div>
      {error && <div className="error-message">{error}</div>}
      {isSubmitting ? (
        <div className='center-align'>
          <ActivityIndicator />
        </div>
      ) : (
        <button type="submit">Create Account</button>
      )}
      <div className='link' onClick={onSwitchToLogin}>Already have an account? Login</div>
    </form>
  );
};

export default CreateAccountForm;
