// src/utils/searchUtils.ts
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import algoliasearch from 'algoliasearch/lite';
import { SearchFilters, AlgoliaHit } from '../types/Search';
import { Article } from '../types/Articles';
import { Source } from '../types/Sources';
import { TopNewsItem } from '../components/TopNews';

export interface SearchConfig {
  articleWhitelist: {
    [key: string]: string | string[];
  };
  articleHomePageLimit?: number;
}

interface TaggedContent {
  status: string;
  objectIDs: string[];
}

let cachedSearchConfig: SearchConfig | null = null;
let cachedAlgoliaConfig: any = null;

const ITEMS_PER_PAGE = 10;

export async function getSearchConfig(): Promise<SearchConfig> {
  if (cachedSearchConfig) return cachedSearchConfig;

  const configDoc = await getDoc(doc(db, 'config', 'search'));
  const articlesConfigDoc = await getDoc(doc(db, 'config', 'articles'));
  
  if (configDoc.exists() && articlesConfigDoc.exists()) {
    const searchConfig = configDoc.data() as SearchConfig;
    const articlesConfig = articlesConfigDoc.data();
    
    cachedSearchConfig = {
      ...searchConfig,
      articleHomePageLimit: articlesConfig.articleHomePageLimit
    };
    return cachedSearchConfig;
  }

  // Default config if none exists in Firestore
  return {
    articleWhitelist: {
      genre: 'editorial'
    },
    articleHomePageLimit: 10 // Default value if not set in Firestore
  };
}

export function buildArticleFilter(config: SearchConfig) {
  return Object.entries(config.articleWhitelist).map(([field, value]) => {
    if (Array.isArray(value)) {
      return `${field} IN ${JSON.stringify(value)}`;
    }
    return `${field} == "${value}"`;
  }).join(' AND ');
}

export async function getAlgoliaConfig() {
  if (cachedAlgoliaConfig) return cachedAlgoliaConfig;

  const searchDoc = await getDoc(doc(db, 'config', 'search'));
  const data = searchDoc.data();

  if (data) {
    cachedAlgoliaConfig = {
      algoliaAppId: data.algoliaAppId,
      algoliaAdminKey: data.algoliaAdminKey,
      algoliaContentsIndexName: data.algoliaContentsIndexName,
      algoliaTopicsIndexName: data.algoliaTopicsIndexName
    };
    return cachedAlgoliaConfig;
  } else {
    console.error('No data found in the Algolia config document');
    return null;
  }
}

export async function performSearch(
  filters: SearchFilters, 
  searchConfig: SearchConfig, 
  currentPage: number, 
  hitsPerPage: number
) {
  const config = await getAlgoliaConfig();
  
  if (!config || !config.algoliaAppId || !config.algoliaAdminKey || !config.algoliaContentsIndexName) {
    throw new Error('Algolia configuration is incomplete');
  }

  // console.log('Algolia config:', config);

  const searchClient = algoliasearch(config.algoliaAppId, config.algoliaAdminKey);
  const index = searchClient.initIndex(config.algoliaContentsIndexName);

  let filterString = '';

  // Add content type filter
  if (filters.contentType === 'all') {
    filterString += `(genre:'news' OR contentType:'article')`;
  } else {
    filterString += `contentType:'${filters.contentType}'`;
  }

  // Add topic filtering
  if (filters.topics && filters.topics.length > 0) {
    const topicFilters = filters.topics.map(topic => `topics:"${topic}"`).join(' AND ');
    filterString += filterString ? ` AND (${topicFilters})` : `(${topicFilters})`;
  }

  // Add date range filtering if present
  if (filters.dateRange[0] && filters.dateRange[1]) {
    const startTimestamp = filters.dateRange[0];
    const endTimestamp = filters.dateRange[1];
    filterString += filterString ? ` AND publishedDate:${startTimestamp} TO ${endTimestamp}` : `publishedDate:${startTimestamp} TO ${endTimestamp}`;
  }

  // Add objectIDs filter if present
  if (filters.objectIDs && filters.objectIDs.length > 0) {
    console.log('ObjectIDs:', filters.objectIDs);
    const objectIDsFilter = filters.objectIDs.map(id => `objectID:'${id}'`).join(' OR ');
    filterString += filterString ? ` AND (${objectIDsFilter})` : `(${objectIDsFilter})`;
  }

  // Add additional filters if present
  if (filters.additionalFilters) {
    filterString += filterString ? ` AND (${filters.additionalFilters})` : filters.additionalFilters;
  }

  const searchParams: any = {
    filters: filterString,
    page: currentPage,
    hitsPerPage: hitsPerPage,
  };

  // Add facets if present
  if (filters.facets) {
    searchParams.facets = filters.facets;
  }

  // Add facetFilters if present
  if (filters.facetFilters) {
    searchParams.facetFilters = filters.facetFilters;
  }

  // Add numericFilters if present
  if (filters.numericFilters) {
    searchParams.numericFilters = filters.numericFilters;
  }

  // console.log('Algolia search params:', searchParams);

  try {
    const { hits, nbPages } = await index.search<AlgoliaHit>(filters.searchTerm, searchParams);
    // console.log('Algolia raw results:', { hits, nbPages });
    return { hits, nbPages };
  } catch (error) {
    console.error('Algolia search error:', error);
    throw error;
  }
}

export async function loadMoreResults(
  filters: SearchFilters, 
  searchConfig: SearchConfig, 
  currentPage: number
): Promise<{ newHits: AlgoliaHit[], hasMore: boolean }> {
  try {
    const { hits, nbPages } = await performSearch(filters, searchConfig, currentPage, ITEMS_PER_PAGE);
    const hasMore = currentPage < nbPages - 1;
    return { newHits: hits, hasMore };
  } catch (error) {
    console.error('Error loading more results:', error);
    throw error;
  }
}

export function transformAlgoliaHit(hit: AlgoliaHit): Article | Source {
  const contentType = hit.contentType;

  if (contentType !== 'article' && contentType !== 'source') {
    console.warn(`Unexpected contentType: ${hit.contentType} for item ${hit.objectID}. Expected 'article' or 'source'.`);
  }

  const commonFields = {
    id: hit.objectID,
    contentType: contentType as 'article' | 'source',
    publishedDate: hit.publishedDate,
    title: hit.title || 'Untitled',
    image: hit.image || undefined,
    topics: hit.topics || [],
    subjects: hit.subjects || [],
    genre: hit.genre || 'unknown',
  };
  
  if (contentType === 'article') {
    return {
      ...commonFields,
      synopsis: hit.synopsis || '',
      sections: hit.sections || [],
      relatedArticles: hit.relatedArticles || [],
      permalink: hit.permalink || '',
      sources: [],
    } as Article;
  } else {
    return {
      ...commonFields,
      url: hit.url || '',
      description: hit.description || '',
    } as Source;
  }
}

export function getTimestamp(date: any): number {
  if (typeof date === 'number') {
    return date;
  } else if (typeof date === 'string') {
    return new Date(date).getTime();
  } else if (date && typeof date === 'object' && '_seconds' in date) {
    return date._seconds * 1000;
  }
  return Date.now();
};

export async function fetchNewsFromAlgolia(
  page: number,
  hitsPerPage: number,
  genre: string = 'news',
  topics: string[] = []
): Promise<{ headlines: TopNewsItem[], nbPages: number }> {
  const searchConfig = await getSearchConfig();
  const filters: SearchFilters = {
    contentType: 'source',
    searchTerm: '',
    dateRange: [undefined, undefined],
    topics: topics,
    additionalFilters: `genre:"${genre}"`
  };
  
  const { hits, nbPages } = await performSearch(filters, searchConfig, page, hitsPerPage);

  const newsItems: TopNewsItem[] = hits
    .map(hit => transformAlgoliaHit(hit))
    .filter((hit): hit is Source => hit.contentType === 'source' && hit.genre === genre)
    .map(hit => ({
      type: 'source',
      content: hit
    }));

  return { headlines: newsItems, nbPages };
}

export async function fetchContentFromAlgolia(
  page: number,
  hitsPerPage: number,
  contentType: 'article' | 'source' | 'news',
  topics: string[] = [],
  genre?: string
): Promise<{ headlines: TopNewsItem[], nbPages: number }> {
  const searchConfig = await getSearchConfig();
  let filterParts = [`contentType:'${contentType === 'news' ? 'source' : contentType}'`];
  
  if (topics.length > 0) {
    const topicFilters = topics.map(topic => `topics:"${topic}"`).join(' OR ');
    filterParts.push(`(${topicFilters})`);
  }
  
  if (contentType === 'source' || contentType === 'news') {
    filterParts.push('genre:"news"');
  } else if (genre) {
    filterParts.push(`genre:"${genre}"`);
  }
  
  const filters: SearchFilters = {
    contentType: contentType === 'news' ? 'source' : contentType,
    searchTerm: '',
    dateRange: [undefined, undefined],
    topics: topics,
    additionalFilters: filterParts.join(' AND ')
  };
  
  const { hits, nbPages } = await performSearch(filters, searchConfig, page, hitsPerPage);

  const contentItems: TopNewsItem[] = hits
    .map(hit => transformAlgoliaHit(hit))
    .filter((hit): hit is Article | Source => 
      hit.contentType === filters.contentType && 
      (contentType === 'source' || contentType === 'news' ? hit.genre === 'news' : true)
    )
    .map(hit => {
      if (hit.contentType === 'article') {
        return {
          type: 'article',
          content: { ...hit, showTimestamp: true }
        } as TopNewsItem;
      } else {
        return {
          type: 'source',
          content: hit
        } as TopNewsItem;
      }
    });

  return { headlines: contentItems, nbPages };
}

export async function fetchArticleByObjectID(objectID: string): Promise<Article | null> {
  const { algoliaAppId, algoliaAdminKey, algoliaContentsIndexName } = await getAlgoliaConfig();
  
  if (!algoliaAppId || !algoliaAdminKey || !algoliaContentsIndexName) {
    throw new Error('Algolia configuration is incomplete');
  }

  const searchClient = algoliasearch(algoliaAppId, algoliaAdminKey);
  const index = searchClient.initIndex(algoliaContentsIndexName);

  try {
    const { hits } = await index.search('', {
      filters: `objectID:${objectID}`,
      hitsPerPage: 1
    });

    if (hits.length > 0) {
      return transformAlgoliaHit(hits[0] as AlgoliaHit) as Article;
    } else {
      console.log(`No article found with objectID ${objectID}`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching article with objectID ${objectID}:`, error);
    return null;
  }
}

export async function fetchTaggedContents(): Promise<Record<string, TaggedContent>> {
  const docRef = doc(db, 'config', 'headlines');
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data()?.tagged || {};
  }
  return {};
}

export async function fetchSourcesByObjectIDs(objectIDs: string[]): Promise<Source[]> {
  const { algoliaAppId, algoliaAdminKey, algoliaContentsIndexName } = await getAlgoliaConfig();
  
  if (!algoliaAppId || !algoliaAdminKey || !algoliaContentsIndexName) {
    throw new Error('Algolia configuration is incomplete');
  }

  const searchClient = algoliasearch(algoliaAppId, algoliaAdminKey);
  const index = searchClient.initIndex(algoliaContentsIndexName);

  try {
    const { hits } = await index.search<AlgoliaHit>('', {
      filters: objectIDs.map(id => `objectID:${id}`).join(' OR '),
      hitsPerPage: objectIDs.length
    });

    return hits
      .filter((hit): hit is AlgoliaHit => hit.contentType === 'source')
      .map(hit => transformAlgoliaHit(hit) as Source);
  } catch (error) {
    console.error('Error fetching sources from Algolia:', error);
    return [];
  }
}
