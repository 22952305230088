// src/contexts/AlertContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import Alert from '../components/Alert';

interface AlertMessage {
  id: number; // Unique identifier for each alert
  message: string;
  type: 'success' | 'alert' | 'warning' | 'info';
}

interface AlertContextType {
  showAlert: (message: string, type?: 'success' | 'alert' | 'warning' | 'info') => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

let nextAlertId = 0; // Global counter to assign unique IDs to alerts

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

export const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertMessage[]>([]);

  const showAlert = (message: string, type: 'success' | 'alert' | 'warning' | 'info' = 'info') => {
    const newAlert: AlertMessage = { id: nextAlertId++, message, type };
    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  const closeAlert = (id: number) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      <div className='alerts-container'>
        {alerts.map((alert) => (
          <Alert key={alert.id} message={alert.message} type={alert.type} onClose={() => closeAlert(alert.id)} />
        ))}
      </div>
    </AlertContext.Provider>
  );
};
