// src/contexts/MessageBoxContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import MessageBox from '../components/MessageBox';

interface MessageBoxOptions {
  label: string;
  action: () => void;
}

interface MessageBoxMessage {
  id: number;
  message: string;
  options: MessageBoxOptions[];
}

interface MessageBoxContextType {
  showMessageBox: (message: string, options: MessageBoxOptions[]) => void;
}

const MessageBoxContext = createContext<MessageBoxContextType | undefined>(undefined);

let nextMessageBoxId = 0;

export const useMessageBox = () => {
  const context = useContext(MessageBoxContext);
  if (!context) {
    throw new Error('useMessageBox must be used within a MessageBoxProvider');
  }
  return context;
};

export const MessageBoxProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [messageBoxes, setMessageBoxes] = useState<MessageBoxMessage[]>([]);

  const showMessageBox = (message: string, options: MessageBoxOptions[]) => {
    const newMessageBox: MessageBoxMessage = { id: nextMessageBoxId++, message, options };
    setMessageBoxes((prevMessageBoxes) => [...prevMessageBoxes, newMessageBox]);
  };

  const closeMessageBox = (id: number) => {
    setMessageBoxes((prevMessageBoxes) => prevMessageBoxes.filter((box) => box.id !== id));
  };

  return (
    <MessageBoxContext.Provider value={{ showMessageBox }}>
      {children}
      <div className='message-boxes-container'>
        {messageBoxes.map((box) => (
          <MessageBox
            key={box.id}
            message={box.message}
            options={box.options.map((option) => ({
              ...option,
              action: () => {
                option.action();
                closeMessageBox(box.id);
              },
            }))}
          />
        ))}
      </div>
    </MessageBoxContext.Provider>
  );
};
