// src/components/SearchResultItem.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Article } from '../types/Articles';
import { Source } from '../types/Sources';
import '../styles/SearchPage.css';
import { renderSpecialCharacters, useIsMobile } from '../utils/general';
import ArticleImage from './ArticleImage';
import TopicList from './TopicList'; // Importing the new TopicList component

interface SearchResultItemProps {
  item: Article | Source;
  onTopicClick: (topic: string) => void;
  showImage: boolean;
  isInserted?: boolean;
}

const SearchResultItem: React.FC<SearchResultItemProps> = ({ item, onTopicClick, showImage, isInserted }) => {
  const isArticle = item.contentType === 'article';
  const article = isArticle ? (item as Article) : null;
  const source = !isArticle ? (item as Source) : null;
  const isMobile = useIsMobile();
  
  const renderLink = () => {
    if (isArticle) {
      return (
        <>
          <Link to={`/article/${article!.permalink}`} className={`h3 ${isInserted ? 'highlight-link' : 'article-link'}`}>
            {renderSpecialCharacters(item.title)}
          </Link>
          <span className="postfix placeholder">CyberHeadliners</span>
        </>
      );
    } else {
      if (source!.url) {
        try {
          const url = new URL(source!.url);
          return (
            <>
              <a href={url.href} target="_blank" rel="noopener noreferrer" className="h3 article-link">
                {renderSpecialCharacters(item.title)}
              </a>
              <span className="postfix placeholder">{url.hostname}</span>
            </>
          );
        } catch (error) {
          console.error('Invalid URL:', source!.url);
        }
      }
      return <span className="search-result-title">{renderSpecialCharacters(item.title)}</span>;
    }
  };

  const renderDate = () => {
    if (!item.publishedDate) return 'Date unavailable';

    const date = new Date(item.publishedDate);

    if (isNaN(date.getTime())) {
      console.error('Invalid date:', item.publishedDate);
      return 'Date unavailable';
    }

    return moment(date).format('D MMM YYYY, h:mm A');
  };

  const getUrl = () => isArticle ? `${window.location.origin}/article/${article!.permalink}` : source!.url;

  const renderImage = () => {
    if (showImage) {
      if (isArticle && article!.image) {
        return (
          <div className="search-result-image">
            <ArticleImage
              imageId={article!.image}
              isThumbnail={true}
              isMobile={isMobile}
            />
          </div>
        );
      } else if (!isArticle && source!.image) {
        return (
          <div className="search-result-image">
            <a href={getUrl()} target="_blank" rel="noopener noreferrer">
              <img src={source!.image} alt={renderSpecialCharacters(item.title)} className="search-result-img" />
            </a>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className={`search-result-item ${isInserted ? 'inserted-headline' : ''}`}>
      {renderImage()}
      <div className={`search-result-content ${showImage ? '' : 'full'}`}>
        <div className="search-result-header">
          {renderLink()}
          {!isArticle && (
            <div className="search-result-url smaller">
              {getUrl()}
            </div>
          )}
        </div>
        <div className="search-result-meta">
          {renderDate()}
        </div>
        <div className="search-result-item">
          {isArticle ? article!.synopsis : source!.description}
        </div>
        <div className="topics-map">
          <TopicList topics={item.topics || []} onTopicClick={onTopicClick} /> {/* Using TopicList */}
        </div>
      </div>
    </div>
  );
};

export default SearchResultItem;
