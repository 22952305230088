// src/pages/ArticlesAdminPage.tsx
import React, { useCallback, useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, limit, QueryDocumentSnapshot, startAfter } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import { Article, RelatedArticle } from '../types/Articles';
import moment from 'moment';
import ActivityIndicator from '../components/ActivityIndicator';
import { getGenre, getCategoryRenderValue } from '../utils/articleUtils';
import { Link } from 'react-router-dom';

const ARTICLES_PER_PAGE = 50;

const ArticlesAdminPage: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot | null>(null);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    document.title = 'Articles Admin';
    fetchArticles();
  }, []);

  const fetchArticles = useCallback(async (lastDocument: QueryDocumentSnapshot | null = null) => {
    try {
      let q = query(
        collection(db, 'articles'),
        orderBy('publishedDate', 'desc'),
        limit(ARTICLES_PER_PAGE)
      );

      if (lastDocument) {
        q = query(q, startAfter(lastDocument));
      }

      const querySnapshot = await getDocs(q);
      const fetchedArticles: Article[] = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Article));

      setArticles(prev => lastDocument ? [...prev, ...fetchedArticles] : fetchedArticles);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
      setHasMore(querySnapshot.docs.length === ARTICLES_PER_PAGE);
    } catch (error) {
      console.error('Error fetching articles: ', error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  }, []);

  const loadMore = async () => {
    if (hasMore && !loadingMore) {
      setLoadingMore(true);
      await fetchArticles(lastDoc);
    }
  };

  if (loading) {
    return (
      <div className='center-container'>
        <ActivityIndicator />
      </div>
    );
  }

  const renderCategories = (categories: string | string[] | undefined) => {
    if (!categories) return null;
    
    return (
      <span className='article-categories'>
        {Array.isArray(categories) 
          ? categories.map((category, index) => (
              <span key={index} className='article-category'>
                {getCategoryRenderValue(category)}
              </span>
            ))
          : <span className='article-category'>{getCategoryRenderValue(categories)}</span>
        }
      </span>
    );
  };

  const renderSources = (sources: string[] | undefined) => {
    if (!sources || sources.length === 0) return <p>No sources available.</p>;
  
    return (
      <ul>
        {sources.map((source, index) => (
          <li key={index}>
            <a href={source} target="_blank" rel="noopener noreferrer">{source}</a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className='main-container'>
      {articles.map((article) => (
        <div key={article.id} className='article-item'>
          <h2><Link to={`/article/${article.permalink}`}>{article.title}</Link></h2>
          <p>{article.synopsis}</p>
          <div>
            <strong>Published Date:</strong> {moment(article.publishedDate).format('LLL')}
          </div>
          <div>
            <strong>Genre:</strong> {getGenre(article.genre)}
          </div>
          <div>
            <strong>Document ID:</strong> {article.id}
          </div>
          <div>
            <strong>Subjects:</strong> {article.subjects ? article.subjects.join(', ') : 'N/A'}
          </div>
          <div>
            <strong>Topics:</strong> {article.topics ? article.topics.join(', ') : 'N/A'}
          </div>
          {article.genre === 'editorial' && (
            <>
              <div>
                <strong>Categories:</strong> {renderCategories(article.categories)}
              </div>
              <div>
                <strong>Access Type:</strong> {article.access || 'N/A'}
              </div>
            </>
          )}
          <div>
            <strong>Sources:</strong>
            {renderSources(article.sources)}
          </div>
          <div>
            <strong>Related Articles:</strong>
            {article.relatedArticles && article.relatedArticles.length > 0 ? (
              <ul>
                {article.relatedArticles.map((relatedArticle: string | RelatedArticle) => {
                  const isString = typeof relatedArticle === 'string';
                  const id = isString ? relatedArticle : relatedArticle.id;
                  const title = isString ? 'Loading...' : relatedArticle.title;
                  const permalink = isString ? '' : relatedArticle.permalink;
                  const publishedDate = isString ? null : relatedArticle.publishedDate;
                  const genre = isString ? '' : relatedArticle.genre;

                  return (
                    <li key={id}>
                      {isString ? (
                        title
                      ) : (
                        <>
                          <Link to={`/article/${permalink}`}>{title}</Link>
                          {' - '}
                          {publishedDate && moment(publishedDate).format('MMMM D, YYYY')}
                          {' - '}
                          {getGenre(genre)}
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p>No related articles found.</p>
            )}
          </div>
          <hr />
        </div>
      ))}
      {hasMore && (
        <button 
          onClick={loadMore} 
          disabled={loadingMore}
          className="load-more-button"
        >
          {loadingMore ? 'Loading...' : 'Load More'}
        </button>
      )}
    </div>
  );
};

export default ArticlesAdminPage;
