// src/components/AllCountriesSelector.tsx
import React, { useMemo } from 'react';
import Select from 'react-select';
import * as allCountries from 'iso-3166-1-alpha-2';
import Flag from 'react-world-flags';
import { selectorStyle } from '../styles/selectorStyle';

interface CountryOption {
  value: string;
  label: string;
  code: string;
}

interface AllCountriesSelectorProps {
  value: string; // Current selected country
  onChange: (selectedCountry: string) => void; // Callback to update location
  disabled?: boolean;
}

const AllCountriesSelector: React.FC<AllCountriesSelectorProps> = ({ value, onChange, disabled }) => {
  const allCountryCodes = allCountries.getCodes();
  
  const countryOptions = useMemo(() => {
    return allCountryCodes.map(code => ({
      value: allCountries.getCountry(code) || code,
      label: allCountries.getCountry(code) || code,
      code
    }));
  }, []);

  const handleChange = (selectedOption: CountryOption | null) => {
    if (selectedOption) {
      onChange(selectedOption.value); // Pass selected country back to parent
    }
  };

  return (
    <Select
      value={countryOptions.find(option => option.value === value) || null}
      onChange={newValue => handleChange(newValue as CountryOption)}
      options={countryOptions}
      className="country-selector"
      classNamePrefix="select"
      placeholder="Select a country..."
      isClearable={false}
      isDisabled={disabled}
      styles={selectorStyle}
      components={{
        Option: ({ innerProps, label, data }) => (
          <div {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
            <Flag code={data.code} height="16" width="24" style={{ marginRight: '8px' }} />
            <span className="country-name">{label}</span>
          </div>
        ),
        SingleValue: ({ children, ...props }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Flag code={props.data.code} height="16" width="24" style={{ marginRight: '8px' }} />
            <span className="country-name">{children}</span>
          </div>
        )
      }}
    />
  );
};

export default AllCountriesSelector;
