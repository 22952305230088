// src/components/ArticleImage.tsx
import React, { useState, useEffect } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';

interface ArticleImageProps {
  imageId: string;
  isThumbnail: boolean;
  isMobile?: boolean;
}

const ArticleImage: React.FC<ArticleImageProps> = ({ imageId, isThumbnail = false, isMobile = false }) => {
  const [imagePath, setImagePath] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchImagePath = async () => {
      try {
        const imageDoc = await getDoc(doc(db, 'images', imageId));
        if (imageDoc.exists()) {
          const { directory } = imageDoc.data();
          let filename = isThumbnail ? (isMobile ? 'small.jpg' : 'medium.jpg') : (isMobile ? 'medium.jpg' : 'large.jpg');
          const url = `https://storage.googleapis.com/cyber-security-headlines/images/${directory}/${filename}`;
          setImagePath(url);
        } else {
          setError('Image not found');
        }
      } catch (error) {
        console.error('Error fetching image data:', error);
        setError('Failed to load image');
      }
    };

    fetchImagePath();
  }, [imageId, isThumbnail, isMobile]);

  if (error) return <p className="image-error">{error}</p>;
  if (!imagePath) return null;

  return <img src={imagePath} alt="Article image" className="article-image" />;
};

export default ArticleImage;
