// src/contexts/ModalContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import Modal from '../components/Modal';

type ModalContextType = {
  showModal: (content: ReactNode, title?: string) => void;
  hideModal: () => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

interface ModalProviderProps {
  children: React.ReactNode; // Define children here
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);
  const [modalTitle, setModalTitle] = useState<string | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (content: ReactNode, title?: string) => {
    setModalContent(content);
    setModalTitle(title);
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
    setModalTitle(undefined);
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {/* The Modal component is rendered here, and its visibility is controlled by isModalOpen */}
      {isModalOpen && (
        <Modal title={modalTitle || 'Default Title'} isOpen={isModalOpen} onClose={hideModal}>
          {modalContent}
        </Modal>
      )}
    </ModalContext.Provider>
  );
};
