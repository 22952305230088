// src/utils/articleUtils.ts
import { Article, RelatedArticle } from '../types/Articles';
import { Timestamp, Query, query, where } from 'firebase/firestore';
import categoryMap from '../config/categoryMap.json';
import { NavigateFunction } from 'react-router-dom';
import moment from 'moment';
import sources from '../config/sources.json';

export const PUBLISHED_STATUS = 'published';

export const ensurePublishedStatus = (q: Query): Query => {
  return query(q, where('status', '==', PUBLISHED_STATUS));
};

export function fetchRelatedArticles(currentArticle: Article): (string | RelatedArticle)[] {
  return currentArticle.relatedArticles || [];
}

export const genreMapping = {
  "news": "News",
  "feature": "Feature",
  "review": "Review",
  "editorial": "Editorial",
  "interview": "Interview",
  "column": "Column",
  "profile": "Profile",
  "howto": "How-To Guide",
  "listicle": "Listicle",
  "opinion": "Opinion",
  "analysis": "Analysis",
  "essay": "Essay"
};

export function getGenre(genre: string): string {
  return genreMapping[genre as keyof typeof genreMapping] || genre;
}

export const getCategoryRenderValue = (category: string): string => {
  return (categoryMap as { [key: string]: string })[category] || category;
};

interface FilterCondition {
  genre?: string | string[];
  timeFrame?: number | string;
  access?: string;
}

export const filterArticles = (articles: Article[], condition: FilterCondition): Article[] => {
  return articles.filter(article => {
    // Check genre
    if (condition.genre) {
      if (Array.isArray(condition.genre)) {
        if (!condition.genre.includes(article.genre)) return false;
      } else {
        if (article.genre !== condition.genre) return false;
      }
    }

    // Check timeFrame
    if (condition.timeFrame) {
      const now = Date.now();
      if (typeof condition.timeFrame === 'number') {
        const timeFrameInMilliseconds = condition.timeFrame * 60 * 60 * 1000; // convert hours to milliseconds
        if (now - article.publishedDate > timeFrameInMilliseconds) return false;
      } else if (condition.timeFrame === 'older') {
        const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
        if (now - article.publishedDate <= twentyFourHoursInMilliseconds) return false;
      }
    }

    // Check access
    if (condition.access && article.access !== condition.access) return false;

    return true;
  });
};

export const navigateToCategory = (navigate: NavigateFunction, category: string) => {
  let state = { initialCategory: 'All', initialGenre: 'All' };

  switch (category) {
    case 'Breaking':
    case 'Headlines':
    case 'Editorials':
      state.initialCategory = category;
      break;
    case 'Analysis':
      state.initialGenre = 'analysis';
      break;
    case 'How-To Guides':
      state.initialGenre = 'howto';
      break;
    case 'Interviews':
      state.initialGenre = 'interview';
      break;
    case 'Profiles':
      state.initialGenre = 'profile';
      break;
  }

  navigate('/articles', { state });
};

export const getRelativeTime = (date: Date) => {
  const now = moment();
  const articleDate = moment(date);
  const diffInMinutes = now.diff(articleDate, 'minutes');
  const diffInHours = now.diff(articleDate, 'hours');
  const diffInDays = now.diff(articleDate, 'days');

  if (diffInMinutes < 1) return 'A moment ago';
  if (diffInMinutes < 5) return 'A few minutes ago';
  if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;
  if (diffInHours < 24) return `${diffInHours} hours ago`;

  if (diffInDays === 1) return 'Yesterday';
  if (diffInDays <= 6) return `${diffInDays} days ago`;
  if (diffInDays === 7) return 'A week ago';

  if (diffInDays > 7 && articleDate.year() === now.year()) {
    return articleDate.format('D MMMM');
  }

  return articleDate.format('D MMMM YYYY');
};

export const getSourceName = (url: string) => {
  const domain = new URL(url).hostname;
  return sources[domain as keyof typeof sources] || domain;
};

export const getArticleImageId = (article: Article): string | null => {
  return article.image || null;
};
