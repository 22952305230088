// src/components/EmailVerification.tsx
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext'; // Use AuthContext
import ActivityIndicator from './ActivityIndicator';
import { redact } from '../utils/general';
import { sendVerificationEmail } from '../utils/userUtils'; // Import the correct function
import { db } from '../utils/firebaseConfig';
import { doc, onSnapshot } from 'firebase/firestore';

interface EmailVerificationProps {
  onVerified: () => void;
}

const COOLDOWN_SECONDS = 30;

const EmailVerification: React.FC<EmailVerificationProps> = ({ onVerified }) => {
  const { authUser, signOut } = useAuth(); // Use AuthContext
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState<string | null>(null);
  const [cooldownTimer, setCooldownTimer] = useState<number>(COOLDOWN_SECONDS);

  useEffect(() => {
    if (authUser) {
      const unsubscribe = onSnapshot(
        doc(db, 'userProfiles', authUser.uid),
        (docSnapshot) => {
          if (docSnapshot.exists() && docSnapshot.data()?.status === true) {
            onVerified(); // Close the modal
          }
        }
      );

      return () => unsubscribe();
    }
  }, [authUser, onVerified, db]);

  useEffect(() => {
    // Start the interval only if cooldownTimer is greater than 0
    if (cooldownTimer > 0) {
      const timer = setInterval(() => {
        // Use the function form to access the latest state value
        setCooldownTimer((prev) => {
          return Math.max(prev - 1, 0);
        });
      }, 1000);
  
      return () => clearInterval(timer);
    }
  }, [cooldownTimer]);

  // Use sendVerificationEmail from userUtils
  const handleSendVerificationEmail = async () => {
    setVerificationStatus(null);
    setIsSendingEmail(true);

    try {
      if (authUser?.email) {
        await sendVerificationEmail(authUser.email); // Use the utility function
        setVerificationStatus('Verification email sent.');
        setCooldownTimer(30);
      } else {
        throw new Error('User email is not available.');
      }
    } catch (error) {
      setVerificationStatus('Failed to send verification email.');
    } finally {
      setIsSendingEmail(false);
    }
  };

  if (!authUser) return null;

  return (
    <div className="form-container">
      <p>A verification email has been sent to {redact(authUser.email)}. Please check your Inbox and Spam folder for the email, and follow the verification link.</p>
      {isSendingEmail ? (
        <div className="center-align">
          <ActivityIndicator />
        </div>
      ) : (
        <>
          <button 
            onClick={handleSendVerificationEmail}
            disabled={cooldownTimer > 0}
            className='secondary'
          >
            {cooldownTimer > 0 ? `Resend email (${cooldownTimer}s)` : 'Resend Verification Email'}
          </button>
          <button onClick={() => signOut()} className="secondary">Logout</button>
        </>
      )}

      {verificationStatus && (
        <span className={`status-message ${verificationStatus.includes('Failed') ? 'error-message' : 'success-message'}`}>
          {verificationStatus}
        </span>
      )}
    </div>
  );
};

export default EmailVerification;
