// src/pages/LoginPage.tsx
import React, { useState } from 'react';
import '../styles/LoginPage.css';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebaseConfig'; // Ensure this is the correct path to your firebaseConfig
import { signInWithEmailAndPassword } from 'firebase/auth';

const LoginPage = () => {
  const [email, setEmail] = useState<string>(''); // Updated to use email
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(''); // Reset any previous error

    // Authenticate with Firebase using the email and password
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home'); // Redirect the user after successful login
    } catch (error) {
      setError('Failed to log in. Please check your email and password.'); // Updated error message
    }
  };

  return (
    <div className='center-container'>
      <div className='login-container'>
        <div className='false-title'>CSH</div>
        <form onSubmit={handleLogin} className='login-form-container'>
          <div className='form-fields-container'>
            <input
              type='email' // Ensure input type is email for proper validation
              placeholder='Email Address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoCorrect='off'
              autoCapitalize='none'
            />
            <input
              type='password'
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className='form-fields-container'>
            {error && <div className='error-message'>{error}</div>}
            <button type='submit'>Login</button>
            <Link to='/create-account'>Create a new account</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
