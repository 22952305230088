// src/contexts/UserContext.tsx
import React, { createContext, useContext, useState } from 'react';
import { UserProfile } from '../types/Users';

interface UserContextType {
  userProfile: UserProfile | null;
  updateProfile: (updatedFields: Partial<UserProfile>) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  const updateProfile = (updatedFields: Partial<UserProfile>) => {
    if (userProfile) {
      const updatedProfile = { ...userProfile, ...updatedFields };
      setUserProfile(updatedProfile);
    }
  };

  return (
    <UserContext.Provider value={{ userProfile, updateProfile }}>
      {children}
    </UserContext.Provider>
  );
};
