// src/components/CountrySelector.tsx
import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import * as allCountries from 'iso-3166-1-alpha-2';
import Flag from 'react-world-flags';
import { selectorStyle } from '../styles/selectorStyle';
import '../styles/CountrySelector.css';
import { useCountry } from '../contexts/CountryContext';
import { useLocation, useNavigate } from 'react-router-dom';

interface CountryOption {
  value: string;
  label: string;
  code: string;
}

const supportedCountryCodes = ['SG', 'AU', 'MY', 'ID']; // Add more as you expand

const CountrySelector: React.FC = () => {
  const { selectedCountry, setSelectedCountry } = useCountry();
  const navigate = useNavigate();
  const location = useLocation();

  const countryOptions = useMemo(() => {
    return supportedCountryCodes
      .map(code => ({
        value: allCountries.getCountry(code) || code,
        label: allCountries.getCountry(code) || code,
        code: code
      }))
      .sort((a, b) => {
        if (a.code === 'SG') return -1;
        if (b.code === 'SG') return 1;
        return a.label.localeCompare(b.label);
      });
  }, []);

  // Check localStorage and set default to 'SG' if no country is selected
  useEffect(() => {
    const storedCountry = localStorage.getItem('selectedCountry');
    if (!storedCountry) {
      setSelectedCountry('Singapore');
    }
  }, [setSelectedCountry]);
  
  const selectedOption = countryOptions.find(option => option.value === selectedCountry) || null;

  const handleChange = (selectedOption: CountryOption | null) => {
    if (selectedOption) {
      setSelectedCountry(selectedOption.value);
      
      // Update URL when country changes
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('country', selectedOption.value);
      
      // Determine if we're on the headlines page
      const isHeadlinesPage = location.pathname === '/headlines';
      
      if (isHeadlinesPage) {
        // If on headlines page, update URL with new country
        navigate(`/headlines?${searchParams.toString()}`);
      } else {
        // If not on headlines page, store the country for later use
        localStorage.setItem('selectedCountry', selectedOption.value);
      }
    }
  };

  // Custom option component to display flag
  const Option = ({ innerProps, label, data }: any) => (
    <div {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
      <Flag code={data.code} height="16" width="24" style={{ marginRight: '8px' }} />
      <span className="country-name">{label}</span>
    </div>
  );

  // Custom single value component to display flag in the selected option
  const SingleValue = ({ children, ...props }: any) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Flag code={props.data.code} height="16" width="24" style={{ marginRight: '8px' }} />
      <span className="country-name">{children}</span>
    </div>
  );

  const customStyles = {
    ...selectorStyle,
    control: (provided: any, state: any) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: 'pointer',
    }),
  };

  return (
    <Select
      value={selectedOption}
      onChange={(newValue) => handleChange(newValue as CountryOption)}
      options={countryOptions}
      className="country-selector"
      classNamePrefix="select"
      placeholder="Select a country..."
      isClearable={false}
      styles={customStyles}
      components={{ Option, SingleValue }}
      isSearchable={false}
      menuPortalTarget={document.body}
    />
  );
}

export default CountrySelector;
