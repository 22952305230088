// src/contexts/ReCaptchaContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';

interface ReCaptchaContextType {
  reCaptchaKey: string | null;
}

const ReCaptchaContext = createContext<ReCaptchaContextType>({ reCaptchaKey: null });

export const ReCaptchaProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [reCaptchaKey, setReCaptchaKey] = useState<string | null>(null);

  useEffect(() => {
    const fetchReCaptchaKey = async () => {
      try {
        const configRef = doc(db, 'config', 'system');
        const configDoc = await getDoc(configRef);
        if (configDoc.exists()) {
          const data = configDoc.data();
          setReCaptchaKey(data.reCAPTCHA?.siteKey || null);
        }
      } catch (error) {
        console.error('Error fetching reCAPTCHA key:', error);
      }
    };

    fetchReCaptchaKey();
  }, []);

  return (
    <ReCaptchaContext.Provider value={{ reCaptchaKey }}>
      {children}
    </ReCaptchaContext.Provider>
  );
};

export const useReCaptcha = () => useContext(ReCaptchaContext);
