// src/components/SettingsForm.tsx
import React, { useEffect, useState, ReactNode } from 'react';
import { useAuth } from '../contexts/AuthContext'; // Use AuthContext
import { useProfile } from '../contexts/ProfileContext'; // Use ProfileContext
import AccountSection from './SettingsSections/AccountSection';
import PreferencesSection from './SettingsSections/PreferencesSection';
import NotificationsSection from './SettingsSections/NotificationsSection';
import '../styles/SettingsForm.css';
import { UserProfile } from '../types/Users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmailVerification from './EmailVerification';

interface SettingsItemProps {
  title: string;
  sectionKey: string;
  activeSection: string;
  onToggle: (section: string) => void;
  children: ReactNode;
  disabled?: boolean;
}

const SettingsItem: React.FC<SettingsItemProps> = ({ 
  title, 
  sectionKey, 
  activeSection, 
  onToggle, 
  children,
  disabled = false
}) => (
  <div className={`accordion-item ${disabled ? 'disabled' : ''}`}>
    <button 
      className="accordion-header" 
      onClick={() => !disabled && onToggle(sectionKey)}
      disabled={disabled}
    >
      {title} <FontAwesomeIcon icon={activeSection === sectionKey ? 'caret-up' : 'caret-down'} />
    </button>
    <div className={`accordion-content ${activeSection === sectionKey ? 'active' : ''}`}>
      {children}
    </div>
  </div>
);

interface SettingsFormProps {
  onClose: () => void;
  initialSection?: 'account' | 'preferences' | 'notifications';
}

const SettingsForm: React.FC<SettingsFormProps> = ({ onClose, initialSection = 'account' }) => {
  const { authUser } = useAuth(); // Use AuthContext
  const { profile, updateProfile } = useProfile(); // Use ProfileContext
  const [activeSection, setActiveSection] = useState<string>(initialSection);

  useEffect(() => {
    setActiveSection(initialSection);
  }, [initialSection]);

  const handleSectionToggle = (section: string) => {
    setActiveSection(prevSection => prevSection === section ? '' : section);
  };

  const handleSave = async (sectionData: Partial<UserProfile>, section: string) => {
    try {
      await updateProfile(sectionData);
    } catch (error) {
      console.error(`Error saving ${section} settings:`, error);
    }
  };

  if (!authUser?.emailVerified) {
    return <EmailVerification onVerified={onClose} />;
  }

  return (
    <div className="settings-form">     
      <div className="accordion">
        <SettingsItem 
          title="Personalize" 
          sectionKey="account" 
          activeSection={activeSection} 
          onToggle={handleSectionToggle}
        >
          <AccountSection 
            onSave={(data: Partial<UserProfile>) => handleSave(data, 'account')}
            onClose={onClose}
          />
        </SettingsItem>

        <SettingsItem 
          title="Preferences" 
          sectionKey="preferences" 
          activeSection={activeSection} 
          onToggle={handleSectionToggle}
        >
          <PreferencesSection 
            user={profile} 
            onSave={(data: Partial<UserProfile>) => handleSave(data, 'preferences')} 
          />
        </SettingsItem>

        <SettingsItem 
          title="Notifications" 
          sectionKey="notifications" 
          activeSection={activeSection} 
          onToggle={handleSectionToggle}
        >
          <NotificationsSection 
            user={profile} 
            onSave={(data: Partial<UserProfile>) => handleSave(data, 'preferences')} 
          />
        </SettingsItem>
      </div>
    </div>
  );
};

export default SettingsForm;
