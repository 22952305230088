// src/components/RelatedContentsList.tsx
import React from 'react';
import { Article } from '../types/Articles';
import { Source } from '../types/Sources';
import ArticleItem from './ArticleItem';
import SourceItem from './SourceItem';

interface RelatedContentsListProps {
  contents: (Article | Source)[];
}

function isArticle(content: Article | Source): content is Article {
  return content.contentType === 'article';
}

const RelatedContentsList: React.FC<RelatedContentsListProps> = ({ contents }) => {
  return (
    <div className="related-contents-list">
      {contents.map((content) => (
        isArticle(content) ? (
          <ArticleItem
            key={content.id}
            article={content}
            showGenre={true}
            showRelativeTime={true}
            showImage={true}
            imageAboveTitle={true}
          />
        ) : (
          <SourceItem
            key={content.id}
            source={content}
            showImage={false}
          />
        )
      ))}
    </div>
  );
};

export default RelatedContentsList;
