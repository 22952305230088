// src/utils/generateArticlePrompt.ts
import { Article } from '../types/Articles';
import { db } from '../utils/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { promptTemplate } from './generateArticlePromptTemplate';

const fetchArticleContent = async (id: string): Promise<string> => {
  const docRef = doc(db, 'sources', id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data().rawContent;
  } else {
    console.error('No such document:', id);
    return '';
  }
};

/* // Approximate token limit (assuming 4 characters per token)
const MAX_TOKENS = 4096;
const AVERAGE_CHAR_PER_TOKEN = 4;
const MAX_CHARS = MAX_TOKENS * AVERAGE_CHAR_PER_TOKEN; */

export const generateArticlePrompt = async (selectedArticles: Article[], writeSample: string): Promise<string> => {
  const writeTopic = (document.getElementById('write-topic') as HTMLInputElement).value;
  const writeType = (document.getElementById('write-type') as HTMLInputElement).value;
  const writeStyle = (document.getElementById('write-style') as HTMLInputElement).value;

  const writeReferences = await Promise.all(
    selectedArticles.map(async (article, index) => {
      const rawContent = await fetchArticleContent(article.id);
      return `${index + 1}. ${article.title}: ${rawContent}`;
    })
  );

  let prompt = promptTemplate
    .replace(/{{writeTopic}}/g, writeTopic)
    .replace(/{{writeType}}/g, writeType)
    .replace(/{{writeStyle}}/g, writeStyle)
    .replace(/{{writeReferences}}/g, writeReferences.join('\n'));

  if (writeSample) {
    prompt += `\n\nWrite the article based on the following sample for structure and style: ${writeSample}`;
  }

  /* // Truncate to estimated max characters
  console.log(`Prompt will truncate at ${MAX_CHARS} characters`);
  if (prompt.length > MAX_CHARS) {
    prompt = prompt.substring(0, MAX_CHARS);
  } */

  console.log('Generated Prompt:', prompt);

  return prompt;
};
