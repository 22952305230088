// src/components/ReferenceArticleSelector.tsx
import React, { useEffect } from 'react';
import moment from 'moment';
import { Article } from '../types/Articles';
import '../styles/ReferenceArticleSelector.css';
import ActivityIndicator from './ActivityIndicator';

interface ReferenceArticleSelectorProps {
  articles: Article[];
  loading: boolean;
  error: string | null;
  selectedArticles: Article[];
  handleArticleClick: (article: Article) => void;
  onArticleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void; // New prop for event handling
}

const ReferenceArticleSelector: React.FC<ReferenceArticleSelectorProps> = ({
  articles,
  loading,
  error,
  selectedArticles,
  handleArticleClick,
  onArticleClick,
}) => {
  useEffect(() => {
    // Save selected article IDs to sessionStorage whenever selectedArticles changes
    const selectedIDs = selectedArticles.map(article => article.id);
    sessionStorage.setItem('selectedArticles', JSON.stringify(selectedIDs));
  }, [selectedArticles]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ActivityIndicator />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div id='reference-articles'>
      {articles.map(article => (
        <div
          className={`article-info ${selectedArticles.some(a => a.id === article.id) ? 'selected' : ''}`}
          key={article.id}
          onClick={(e) => {
            if (onArticleClick) onArticleClick(e); // Prevent event propagation
            handleArticleClick(article);
          }}
        >
          <div className='flex-gap'>
            <div className='h3'>{article.title}</div>
            <small>{moment((article.publishedDate as any).toDate()).format('MMMM D YYYY')}</small>
          </div>
          <div>{article.synopsis}</div>
          <small>{[...(article.subjects || []), ...(article.topics || [])].sort().join(', ')}</small>
        </div>
      ))}
    </div>
  );
};

export default ReferenceArticleSelector;
