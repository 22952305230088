// src/contexts/SideMenuContext.tsx
import React, { ReactNode, createContext, useContext, useState } from 'react';

interface SideMenuContextType {
  isSideMenuVisible: boolean;
  showSideMenu: () => void;
  hideSideMenu: () => void;
  toggleSideMenu: () => void;
}

interface SideMenuProviderProps {
  children: ReactNode; // This line allows the component to accept children.
}

const SideMenuContext = createContext<SideMenuContextType | undefined>(undefined);

export const SideMenuProvider: React.FC<SideMenuProviderProps> = ({ children }) => {
  const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);

  const showSideMenu = () => setIsSideMenuVisible(true);
  const hideSideMenu = () => setIsSideMenuVisible(false);
  const toggleSideMenu = () => setIsSideMenuVisible(!isSideMenuVisible);

  return (
    <SideMenuContext.Provider value={{ isSideMenuVisible, showSideMenu, hideSideMenu, toggleSideMenu }}>
      {children}
    </SideMenuContext.Provider>
  );
};

export const useSideMenu = () => {
  const context = useContext(SideMenuContext);
  if (context === undefined) {
    throw new Error('useSideMenu must be used within a SideMenuProvider');
  }
  return context;
};
