// src/components/AuthForms.tsx
import React, { useState } from 'react';
import LoginForm from './LoginForm';
import CreateAccountForm from './CreateAccountForm';

interface AuthFormsProps {
  onSuccess: (isNewAccount: boolean) => void;
  onClose: () => void;
}

const AuthForms: React.FC<AuthFormsProps> = ({ onSuccess, onClose }) => {
  const [isLogin, setIsLogin] = useState(true);

  return (
    <div className="auth-forms">
      {isLogin ? (
        <LoginForm 
          onSuccess={() => onSuccess(false)}
          onSwitchToCreate={() => setIsLogin(false)}
        />
      ) : (
        <CreateAccountForm 
          onSuccess={() => onSuccess(true)}
          onSwitchToLogin={() => setIsLogin(true)}
        />
      )}
    </div>
  );
};

export default AuthForms;
