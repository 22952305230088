// src/utils/sortSubjects.ts
interface SubjectsMap {
  [subject: string]: {
    count: number;
    articles: any[];
  };
}

const sortSubjects = (subjectsMap: SubjectsMap): string[] => {
  return Object.keys(subjectsMap)
    .sort((a, b) => subjectsMap[b].count - subjectsMap[a].count)
    .slice(0, 5);
};

export default sortSubjects;
