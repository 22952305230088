// src/pages/UnprocessedSourcesAdminPage.tsx
import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, Timestamp } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import moment from 'moment';
import ActivityIndicator from '../components/ActivityIndicator';
import { Link } from 'react-router-dom';

interface UnprocessedSource {
  id: string;
  cluster: string[];
  existingUrl: string;
  existingDocId: string;
  url: string;
  timestamp: Timestamp;
  status: string;
}

const UnprocessedSourcesAdminPage: React.FC = () => {
  const [unprocessedSources, setUnprocessedSources] = useState<UnprocessedSource[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = 'Unprocessed Sources Admin';
  }, []);

  useEffect(() => {
    const fetchUnprocessedSources = async () => {
      try {
        const sourcesRef = collection(db, 'unprocessedSources');
        const q = query(sourcesRef, orderBy('timestamp', 'desc'));
        const querySnapshot = await getDocs(q);
        const fetchedSources: UnprocessedSource[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          fetchedSources.push({
            id: doc.id,
            ...data,
            timestamp: data.timestamp as Timestamp
          } as UnprocessedSource);
        });
        setUnprocessedSources(fetchedSources);
      } catch (error) {
        console.error('Error fetching unprocessed sources: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUnprocessedSources();
  }, []);

  if (loading) {
    return (
      <div className='center-container'>
        <ActivityIndicator />
      </div>
    );
  }

  return (
    <div className='main-container'>
      <h1>Unprocessed Sources</h1>
      <table className="unprocessed-sources-table">
        <thead>
          <tr>
            <th>URL</th>
            <th>Timestamp</th>
            {/* <th>Status</th> */}
            <th>Existing URL</th>
            <th>Existing Doc ID</th>
            <th>Cluster</th>
          </tr>
        </thead>
        <tbody>
          {unprocessedSources.map((source) => (
            <tr key={source.id}>
              <td><a href={source.url} target="_blank" rel="noopener noreferrer">{source.url}</a></td>
              <td>{moment(source.timestamp.toDate()).format('LLL')}</td>
              {/* <td>{source.status}</td> */}
              <td><a href={source.existingUrl} target="_blank" rel="noopener noreferrer">{source.existingUrl}</a></td>
              <td><Link to={`/article/${source.existingDocId}`}>{source.existingDocId}</Link></td>
              <td>
                <ul>
                  {source.cluster.map((url, index) => (
                    <li key={index}><a href={url} target="_blank" rel="noopener noreferrer">{url}</a></li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UnprocessedSourcesAdminPage;
