// src/components/SourceList.tsx
import React from 'react';
import { Source } from '../types/Sources';
import SourceItem from './SourceItem';

interface SourceListProps {
  sources: Source[];
  totalCount: number;
  onExpand: () => void;
  showImage?: boolean;
}

const SourceList: React.FC<SourceListProps> = ({
  sources,
  totalCount,
  onExpand,
  showImage = false,
}) => {
  const displayedSources = sources.slice(0, totalCount);

  return (
    <div className="source-list flex-gap-medium">
      {displayedSources.map((source) => (
        <SourceItem 
          key={source.id} 
          source={source} 
          showImage={showImage}
        />
      ))}
    </div>
  );
};

export default SourceList;
