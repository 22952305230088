// src/contexts/FloatingWidgetContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import FloatingWidget from '../components/FloatingWidget';

interface FloatingWidgetContextProps {
  setPosition: (position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right') => void;
  setContent: (content: ReactNode) => void;
  clearWidget: () => void; // For removing content and hiding the widget
}

const FloatingWidgetContext = createContext<FloatingWidgetContextProps | undefined>(undefined);

export const FloatingWidgetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [position, setPosition] = useState<'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'>('bottom-right');
  const [content, setContent] = useState<ReactNode>(null);

  const clearWidget = () => setContent(null);

  return (
    <FloatingWidgetContext.Provider value={{ setPosition, setContent, clearWidget }}>
      {children}
      {content && <FloatingWidget position={position}>{content}</FloatingWidget>}
    </FloatingWidgetContext.Provider>
  );
};

export const useFloatingWidget = () => {
  const context = useContext(FloatingWidgetContext);
  if (!context) throw new Error('useFloatingWidget must be used within a FloatingWidgetProvider');
  return context;
};
