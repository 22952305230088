// src/pages/ArticleWriter.tsx
import React, { useState, useEffect } from 'react';
import SubjectSection from '../components/SubjectSection';
import InputFields from '../components/InputFields';
import GenerateArticle from '../components/ArticleEditor';
import { useArticleData } from '../hooks/useArticleData';
import useSubjectsMap from '../hooks/useSubjectsMap';
import useArticleSelection from '../hooks/useArticleSelection';
import sortSubjects from '../utils/sortSubjects';
import Modal from '../components/Modal';

const ArticleWriter = () => {
  const [expandedSubjects, setExpandedSubjects] = useState<Record<string, boolean>>({});
  const { loading, error, articles } = useArticleData();
  const subjectsMap = useSubjectsMap(articles);
  const { selectedArticles, setSelectedArticles, handleArticleClick } = useArticleSelection();
  const sortedSubjects = sortSubjects(subjectsMap);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [writeSample, setWriteSample] = useState<string>('');

  useEffect(() => {
    const storedSelectedIDs = JSON.parse(sessionStorage.getItem('selectedArticles') || '[]');
    if (articles.length > 0 && storedSelectedIDs.length > 0) {
      const initiallySelectedArticles = articles.filter(article => storedSelectedIDs.includes(article.id));
      setSelectedArticles(initiallySelectedArticles);
    }

    const storedWriteSample = sessionStorage.getItem('writeSample');
    if (storedWriteSample) {
      setWriteSample(storedWriteSample);
    }
  }, [articles, setSelectedArticles]);

  const toggleSubject = (subject: string) => {
    setExpandedSubjects(prev => ({
      ...prev,
      [subject]: !prev[subject],
    }));
  };

  const handleProvideSample = () => {
    setIsModalOpen(true);
  };

  const handleSaveSample = () => {
    sessionStorage.setItem('writeSample', writeSample);
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='main-container'>
      <div className='outside-container'>
        <div className='h1'>Article Writer</div>
      </div>
      <div className='sections-container'>
        <div className='section-container flex-gap-medium'>
          <div>What do you want to write about?</div>
          <InputFields />
          <div>
            Select reference content to build your article
          </div>
          <SubjectSection
            subjectsMap={subjectsMap}
            sortedSubjects={sortedSubjects}
            expandedSubjects={expandedSubjects}
            toggleSubject={toggleSubject}
            loading={loading}
            error={error}
            selectedArticles={selectedArticles}
            handleArticleClick={handleArticleClick}
            limit={25}
          />
          <button className='secondary full' onClick={handleProvideSample}>Provide Sample For Writing Style</button>
        </div>
        <div className='section-container'>
          <GenerateArticle selectedArticles={selectedArticles} writeSample={writeSample} />
        </div>
      </div>

      <Modal title='Provide Sample For Writing Style' isOpen={isModalOpen} onClose={handleCloseModal}>
        <textarea
          style={{ width: '100%', flex: '1' }}
          rows={ 20 }
          placeholder='Provide a sample article for writing style...'
          value={writeSample}
          onChange={(e) => setWriteSample(e.target.value)}
        />
        <div className='flex-gap-row'>
          <button onClick={handleCloseModal} className='secondary full'>Cancel</button>
          <button onClick={handleSaveSample} className='primary full'>Save Sample</button>
        </div>
      </Modal>
    </div>
  );
};

export default ArticleWriter;
