// src/components/ArticleList.tsx
import React from 'react';
import { Article, RelatedArticle } from '../types/Articles';
import ArticleItem from './ArticleItem';

interface ArticleListProps {
  articles: (Article | RelatedArticle)[];
  showGenre?: boolean;
  showRelativeTime?: boolean;
  flexDirection?: 'row' | 'column';
  useRowGap?: boolean;
  totalCount: number;
  onExpand: () => void;
  isBreaking?: boolean;
  showImage?: boolean;
}

const ArticleList: React.FC<ArticleListProps> = ({
  articles,
  showGenre = false,
  showRelativeTime = false,
  flexDirection = 'column',
  useRowGap = false,
  totalCount,
  onExpand,
  isBreaking = false,
  showImage = false,
}) => {
  const displayedArticles = articles.slice(0, totalCount);

  return (
    <div className="article-list">
      {displayedArticles.map((article) => (
        <ArticleItem
          key={article.id}
          article={article}
          showGenre={showGenre}
          showRelativeTime={showRelativeTime}
          isBreaking={isBreaking}
          showImage={showImage}
        />
      ))}
    </div>
  );
};

export default ArticleList;
