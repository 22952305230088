// src/components/SourceItem.tsx
import React from 'react';
import { Source } from '../types/Sources';
import { getRelativeTime, getSourceName } from '../utils/articleUtils';
import '../styles/SourceItem.css';
import { renderSpecialCharacters } from '../utils/general';

interface SourceItemProps {
  source: Source;
  showImage?: boolean;
}

const SourceItem: React.FC<SourceItemProps> = ({ source, showImage = false }) => {
  return (
    <div className="source-item flex-gap-small">
      {showImage && source.image && (
        <div className="source-item-image-container">
          <img src={source.image} alt={source.title} className="source-item-image" />
        </div>
      )}
      <div className="source-item-main-container flex-gap-small">
        <div>
          <a className="article-link h3" href={source.url} target="_blank" rel="noopener noreferrer">
            {renderSpecialCharacters(source.title)}
          </a>
          <span className='postfix smaller'>{getRelativeTime(new Date(source.publishedDate))}</span>
        </div>
        <div>{renderSpecialCharacters(source.description)}<span className="postfix placeholder smaller">{getSourceName(source.url)}</span></div>
      </div>
    </div>
  );
};

export default SourceItem;
