// src/hooks/useArticleData.ts
import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot, Timestamp } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import { Article } from '../types/Articles';

export const useArticleData = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const sevenDaysAgo = Timestamp.fromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
    const articlesQuery = query(
      collection(db, 'sources'),
      where('publishedDate', '>=', sevenDaysAgo),
      where('status', '==', 'available'),
      where('analyzed', '==', true),
      orderBy('publishedDate', 'desc')
    );

    const unsubscribe = onSnapshot(articlesQuery, (snapshot) => {
      const fetchedArticles: Article[] = [];
      snapshot.forEach(doc => {
        fetchedArticles.push({ ...doc.data(), id: doc.id } as Article);
      });
      setArticles(fetchedArticles);
      setLoading(false);
    }, (err) => {
      console.error('Failed to fetch articles:', err);
      setError('Failed to fetch articles. Please try again.');
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { articles, loading, error };
};
