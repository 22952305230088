// src/components/ExpandLine.tsx
import React from 'react';
import '../styles/HomePage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ExpandLineProps {
  category: string;
  onClick?: () => void;
}

const ExpandLine: React.FC<ExpandLineProps> = ({ category, onClick }) => {
  return (
    <div className='expand-line' onClick={onClick}>
      More {category} <FontAwesomeIcon icon='caret-down' />
    </div>
  );
};

export default ExpandLine;
