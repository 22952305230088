// src/contexts/TopicDisplayContext.tsx
import React, { createContext, useState, useContext, ReactNode, useEffect, Dispatch, SetStateAction } from 'react';

interface TopicDisplayContextType {
  showObjectID: boolean;
  toggleShowObjectID: () => void;
}

const TopicDisplayContext = createContext<TopicDisplayContextType | undefined>(undefined);

export const TopicDisplayProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [showObjectID, setShowObjectID] = useState<boolean>(() => {
    const stored = sessionStorage.getItem('showObjectID');
    return stored ? JSON.parse(stored) : false;
  });

  useEffect(() => {
    // sessionStorage.setItem('showObjectID', JSON.stringify(showObjectID));
    sessionStorage.setItem('showObjectID', 'true');
  }, [showObjectID]);

  const toggleShowObjectID = () => {
    setShowObjectID((prev: boolean) => !prev);
  };

  return (
    <TopicDisplayContext.Provider value={{ showObjectID, toggleShowObjectID }}>
      {children}
    </TopicDisplayContext.Provider>
  );
};

export const useTopicDisplay = () => {
  const context = useContext(TopicDisplayContext);
  if (context === undefined) {
    throw new Error('useTopicDisplay must be used within a TopicDisplayProvider');
  }
  return context;
};
