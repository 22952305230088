// src/components/FeatureSource.tsx
import React from 'react';
import { Source } from '../types/Sources';
import TopicList from './TopicList';
import { useNavigate } from 'react-router-dom';
import { getRelativeTime, getSourceName } from '../utils/articleUtils';
import { renderSpecialCharacters } from '../utils/general';

interface FeatureSourceProps {
  source: Source;
}

const FeatureSource: React.FC<FeatureSourceProps> = ({ source }) => {
  const navigate = useNavigate();

  const handleTopicClick = (topic: string) => {
    navigate(`/search?topic=${encodeURIComponent(topic)}`);
  };

  return (
    <div className="feature-source flex-gap-small">
      <div className="feature-image-container">
        <img
          src={source.image}
          alt={source.title}
          className="feature-image"
          loading="lazy"
        />
      </div>
      <div className="feature-content flex-gap-tiny">
        <div>
          <a href={source.url} target="_blank" rel="noopener noreferrer" className='article-link h2'>
            {renderSpecialCharacters(source.title)}
          </a>
          <div className=''>{getRelativeTime(new Date(source.publishedDate))}</div>
        </div>
        <p className="feature-description">{source.description}<span className="postfix placeholder smaller">{getSourceName(source.url)}</span></p>
        <div className="feature-meta">
          <TopicList topics={source.topics} onTopicClick={handleTopicClick} />
        </div>
      </div>
    </div>
  );
};

export default FeatureSource;
