// src/components/ProtectedRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthStatus } from '../utils/useAuthStatus';
import ActivityIndicator from './ActivityIndicator';
import { auth } from '../utils/firebaseConfig';

interface ProtectedRouteProps {
  children: JSX.Element;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuthStatus();

  if (isLoading) {
    return (
      <div className='center-container'>
        <ActivityIndicator />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to='/login' replace />;
  }

  if (auth.currentUser && !auth.currentUser.emailVerified) {
    return <Navigate to='/verify-email' replace />;
  }

  return children;
};
