// src/utils/topicUtils.ts
import { getAlgoliaConfig } from './searchUtils';
import { Dispatch, SetStateAction } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { TopicHit } from '../types/Topics';

type FiltersWithTopics = {
  topics: string[];
  [key: string]: any;
};

export interface Topic {
  objectID: string;
  name: string;
  count: number;
}

export const fetchTopTopics = async (days: number, limit: number = 10, country: string | null = null): Promise<Topic[]> => {
  try {
    const config = await getAlgoliaConfig();
    if (!config || !config.algoliaAppId || !config.algoliaAdminKey || !config.algoliaTopicsIndexName) {
      throw new Error('Algolia configuration is incomplete');
    }

    const now = new Date();
    const pastDate = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);

    const searchClient = algoliasearch(config.algoliaAppId, config.algoliaAdminKey);
    const index = searchClient.initIndex(config.algoliaTopicsIndexName);

    const { hits } = await index.search('', {
      filters: `publishedDate:${pastDate.getTime()} TO ${now.getTime()} AND NOT status:deleted`,
      hitsPerPage: 1000,
      attributesToRetrieve: ['objectID', 'canonicalName', 'status'], // Include 'objectID'
    });
    
    // Adjust the mapping logic to include 'objectID'
    const topicCounts: Record<string, { objectID: string; count: number }> = {};
    
    hits.forEach((hit: any) => {
      const topic = hit.canonicalName;
      if (topic && hit.status !== 'deleted' && (!country || topic !== country)) {
        if (!topicCounts[topic]) {
          topicCounts[topic] = { objectID: hit.objectID, count: 0 }; // Initialize with objectID and count
        }
        topicCounts[topic].count += 1; // Increment count
      }
    });
    
    // Return topics including objectID, name, and count
    const sortedTopics = Object.entries(topicCounts)
      .sort(([, a], [, b]) => b.count - a.count)
      .slice(0, limit)
      .map(([name, { objectID, count }]) => ({ objectID, name, count }));
    
    return sortedTopics as Topic[];
  } catch (error) {
    console.error('Error in fetchTopTopics:', error);
    throw error;
  }
};

export const handleTopicClick = <T extends FiltersWithTopics>(
  topic: string,
  setFilters: Dispatch<SetStateAction<T>>,
  setNonExistentTopic: Dispatch<SetStateAction<string | null>>,
  navigate?: (path: string) => void
) => {
  setFilters(prev => ({
    ...prev,
    topics: prev.topics.includes(topic) ? prev.topics : [...prev.topics, topic]
  }));
  setNonExistentTopic(null);

  if (navigate) {
    navigate(`/search?topic=${encodeURIComponent(topic)}`);
  }
};

export const searchTopicsAlgolia = async (query: string, limit: number = 100): Promise<{ value: string; label: string }[]> => {
  try {
    const config = await getAlgoliaConfig();

    if (!config || !config.algoliaAppId || !config.algoliaAdminKey || !config.algoliaTopicsIndexName) {
      console.error('Incomplete Algolia configuration:', config);
      throw new Error('Algolia configuration is incomplete');
    }

    const searchClient = algoliasearch(config.algoliaAppId, config.algoliaAdminKey);
    const index = searchClient.initIndex(config.algoliaTopicsIndexName);

    const { hits } = await index.search(query, {
      hitsPerPage: limit,
      attributesToRetrieve: ['canonicalName', 'status'],
      filters: 'NOT status:deleted',  // This line ensures we don't get deleted topics
      restrictSearchableAttributes: ['canonicalName'],
    });

    return hits
      .filter((hit: any) => hit.objectID && hit.canonicalName && hit.status !== 'deleted')
      .map((hit: any) => ({
        value: hit.objectID, // Use objectID as value
        label: hit.canonicalName // Use canonicalName as label
      }));
  } catch (error) {
    console.error('Error in searchTopicsAlgolia:', error);
    return []; // Return an empty array instead of throwing to prevent breaking the UI
  }
};

export async function fetchCanonicalName(objectID: string): Promise<string | null> {
  const showObjectID = JSON.parse(sessionStorage.getItem('showObjectID') || 'false');
  
  if (showObjectID) {
    return objectID;
  }

  try {
    const config = await getAlgoliaConfig();
    if (!config || !config.algoliaAppId || !config.algoliaAdminKey || !config.algoliaTopicsIndexName) {
      throw new Error('Algolia configuration is incomplete');
    }

    const searchClient = algoliasearch(config.algoliaAppId, config.algoliaAdminKey);
    const index = searchClient.initIndex(config.algoliaTopicsIndexName);

    const { hits } = await index.search<TopicHit>('', {
      filters: `objectID:"${objectID}"`,
      hitsPerPage: 1,
      attributesToRetrieve: ['canonicalName'],
    });

    if (hits.length > 0 && hits[0].canonicalName) {
      return hits[0].canonicalName;
    }
    return objectID;
  } catch (error) {
    console.error('Error fetching canonicalName:', error);
    return objectID;
  }
}
