// src/components/SubjectSection.tsx
import React, { useEffect, useState } from 'react';
import ReferenceArticleSelector from './ReferenceArticleSelector';
import { Article } from '../types/Articles';
import { fetchTopTopics, Topic } from '../utils/topicUtils';
import ActivityIndicator from './ActivityIndicator';

interface SubjectSectionProps {
  subjectsMap: Record<string, { count: number; articles: Article[] }>;
  sortedSubjects: string[];
  expandedSubjects: Record<string, boolean>;
  toggleSubject: (subject: string) => void;
  loading: boolean;
  error: string | null;
  selectedArticles: Article[];
  handleArticleClick: (article: Article) => void;
  limit: number; // Add this line to include the limit prop
}

const SubjectSection: React.FC<SubjectSectionProps> = ({
  subjectsMap,
  sortedSubjects,
  expandedSubjects,
  toggleSubject,
  loading,
  error,
  selectedArticles,
  handleArticleClick,
  limit, // Destructure the limit prop
}) => {
  const [fetchedSubjects, setFetchedSubjects] = useState<Topic[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subjects = await fetchTopTopics(7, limit); // Use the limit prop here
        setFetchedSubjects(subjects);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [limit]);

  return (
    <div className='list-synopses common-border-div'>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ActivityIndicator />
        </div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        fetchedSubjects.map((subject) => (
          <div key={subject.name} className='expand-header-container'>
            <div onClick={() => toggleSubject(subject.name)} className='expand-header'>
              {subject.name} ({subject.count})
            </div>
            {expandedSubjects[subject.name] && (
              <ReferenceArticleSelector
                articles={subjectsMap[subject.name]?.articles || []}
                loading={loading}
                error={error}
                selectedArticles={selectedArticles}
                handleArticleClick={handleArticleClick}
                onArticleClick={(e) => e.stopPropagation()} // Prevent event propagation
              />
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default SubjectSection;
