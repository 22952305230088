// src/components/TopicSelector.tsx
import React, { useState, useCallback, useEffect } from 'react';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import { selectorStyle } from '../styles/selectorStyle';
import { searchTopicsAlgolia, fetchTopTopics, fetchCanonicalName } from '../utils/topicUtils';
import { useTopicDisplay } from '../contexts/TopicDisplayContext';

interface TopicSelectorProps {
  selectedTopics: string[];
  onChange: (topics: string[]) => void;
}

const TopicSelector: React.FC<TopicSelectorProps> = ({ selectedTopics, onChange }) => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
  const [initialTopics, setInitialTopics] = useState<{ value: string; label: string }[]>([]);
  const [objectIdToCanonical, setObjectIdToCanonical] = useState<{ [key: string]: string }>({});
  const { showObjectID } = useTopicDisplay();

  useEffect(() => {
    const fetchInitialTopics = async () => {
      const topTopics = await fetchTopTopics(7, 10);
      const formattedTopics = await Promise.all(topTopics.map(async topic => ({
        value: topic.objectID,
        label: showObjectID ? topic.objectID : await fetchCanonicalName(topic.objectID) || topic.objectID
      })));
      formattedTopics.sort((a, b) => a.label.localeCompare(b.label));
      setInitialTopics(formattedTopics);
      setOptions(formattedTopics);
    };
    fetchInitialTopics();
  }, [showObjectID]);

  const searchTopics = useCallback(
    debounce(async (inputValue: string) => {
      if (inputValue.length >= 2) {
        const newOptions = await searchTopicsAlgolia(inputValue);
        const formattedOptions = await Promise.all(newOptions.map(async option => ({
          value: option.value,
          label: showObjectID ? option.value : await fetchCanonicalName(option.value) || option.value
        })));
        setOptions(formattedOptions);
      } else {
        setOptions(initialTopics);
      }
    }, 300),
    [initialTopics, showObjectID]
  );

  const handleInputChange = (inputValue: string, { action }: { action: string }) => {
    if (action !== 'input-blur' && action !== 'menu-close') {
      if (inputValue === '') {
        setOptions(initialTopics);
      } else {
        searchTopics(inputValue);
      }
    }
  };

  return (
    <Select
      isMulti
      name="topics"
      options={options}
      className="topic-selector"
      classNamePrefix="select"
      value={selectedTopics.map(topic => ({
        value: topic,
        label: showObjectID ? topic : (objectIdToCanonical[topic] || topic)
      }))}
      onChange={async (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        console.log('Selected objectIDs:', selectedValues);
      
        if (!showObjectID) {
          const newMapping = { ...objectIdToCanonical };
          for (const objectID of selectedValues) {
            if (!newMapping[objectID]) {
              const canonicalName = await fetchCanonicalName(objectID);
              if (canonicalName) {
                newMapping[objectID] = canonicalName;
              } else {
                console.error(`CanonicalName not found for objectID: ${objectID}`);
              }
            }
          }
          setObjectIdToCanonical(newMapping);
        }
      
        onChange(selectedValues);
      }}
      onInputChange={handleInputChange}
      placeholder="Type to search topics..."
      noOptionsMessage={({ inputValue }) => 
        inputValue.length < 2 ? "Type at least 2 characters to search" : "No topics found"
      }
      styles={selectorStyle}
    />
  );
};

export default TopicSelector;
