// src/pages/ArticlePage.tsx
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import { Article, Section, SubSection } from '../types/Articles';
import ActivityIndicator from '../components/ActivityIndicator';
import { getGenre } from '../utils/articleUtils';
import '../styles/ArticlePage.css';
import { useIsMobile } from '../utils/general';
import CategoryList from '../components/CategoryList';
import { ensurePublishedStatus } from '../utils/articleUtils';
import ArticleImage from '../components/ArticleImage';
import SourceList from '../components/SourceList';
import { Source } from '../types/Sources';
import { getSearchConfig, performSearch, transformAlgoliaHit, fetchArticleByObjectID, fetchSourcesByObjectIDs } from '../utils/searchUtils';
import RelatedContentsList from '../components/RelatedContentsList';

const ArticlePage: React.FC = () => {
  const location = useLocation();
  const [article, setArticle] = useState<Article | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [sources, setSources] = useState<Source[]>([]);
  const isMobile = useIsMobile();
  const [relatedContents, setRelatedContents] = useState<(Article | Source)[]>([]);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3150152879069757';
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);
  
    // Cleanup script when component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []); 

  useEffect(() => {
    const fetchArticleAndRelated = async () => {
      try {
        const pathParts = location.pathname.split('/');
        const permalink = pathParts[pathParts.length - 1];
    
        if (!permalink) {
          setError('Article permalink is missing');
          return;
        }
    
        const articlesRef = collection(db, 'articles');
        let q = query(articlesRef, where('permalink', '==', permalink));
        q = ensurePublishedStatus(q);
        const querySnapshot = await getDocs(q);
    
        if (!querySnapshot.empty) {
          const docSnapshot = querySnapshot.docs[0];
          const articleData = { ...docSnapshot.data() as Article, id: docSnapshot.id };
  
          // Fetch and log Algolia data
          const algoliaArticle = await fetchArticleByObjectID(articleData.id);
          if (algoliaArticle) {
            articleData.title = algoliaArticle.title;
            articleData.synopsis = algoliaArticle.synopsis;
            articleData.publishedDate = algoliaArticle.publishedDate;
            articleData.genre = algoliaArticle.genre;
          }
  
          setArticle(articleData);
          document.title = articleData.title;
  
          // Fetch related contents from Algolia
          if (articleData.relatedContents && articleData.relatedContents.length > 0) {
            const relatedContentsData = await fetchRelatedContents(articleData.relatedContents);
            setRelatedContents(relatedContentsData);
          } else {
            setRelatedContents([]);
          }
  
          // Fetch sources from Algolia
          if (articleData.sources && articleData.sources.length > 0) {
            const algoliaSources = await fetchSourcesByObjectIDs(articleData.sources);
            setSources(algoliaSources);
          } else {
            setSources([]);
          }
        } else {
          setError('Article not found');
        }
      } catch (err) {
        console.error('Error fetching article:', err);
        setError('Failed to fetch article');
      } finally {
        setLoading(false);
      }
    };
  
    fetchArticleAndRelated();
  }, [location]);

  const fetchRelatedContents = async (relatedContents: Array<{id: string}>) => {
    const contentIds = relatedContents.map(content => content.id);
    console.log('Related content IDs:', contentIds);
  
    const searchConfig = await getSearchConfig();
    const { hits } = await performSearch(
      { 
        searchTerm: '', 
        contentType: 'all',
        dateRange: [undefined, undefined],
        topics: [],
        objectIDs: contentIds
      },
      searchConfig,
      0,
      contentIds.length
    );
  
    console.log('Hits:', hits);
    return hits.map(hit => transformAlgoliaHit(hit));
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ActivityIndicator />
      </div>
    );
  }

  if (error || !article) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {error || 'Article not found'}
      </div>
    );
  }

  const renderSection = (section: Section) => (
    <div className='section'>
      <div className='h2'>{section.header}</div>
      {section.paragraphs.map((paragraph: string, pIndex: number) => (
        <p key={pIndex}>{paragraph}</p>
      ))}
      {section.subSections && section.subSections.map((subSection: SubSection, ssIndex: number) => (
        <div key={ssIndex} className='sub-section'>
          <div className='h3'>{subSection.subHeader}</div>
          {subSection.paragraphs.map((paragraph: string, pIndex: number) => (
            <p key={pIndex}>{paragraph}</p>
          ))}
        </div>
      ))}
    </div>
  );

  return (
    <div className='main-container'>
      <div className='article-container flex-gap-large'>
        <div className='article-section flex-gap-small'>
          <div className='article-header'>
            <Link 
              className='article-genre' 
              to="/articles" 
              state={{ 
                initialCategory: 'All', 
                initialGenre: article.genre 
              }}
            >
              {getGenre(article.genre)}
            </Link>
            {article.categories && <CategoryList categories={article.categories} />}
            <div className='h1'>{article.title}</div>
            <div className='published-date'>
              {moment(article.publishedDate).format('MMMM D, YYYY, h:mm A')}
            </div>
            {article.image && (
              <ArticleImage
                imageId={article.image}
                isThumbnail={false}
                isMobile={isMobile}
              />
            )}
            <p className='article-synopsis'>{article.synopsis}</p>
          </div>
          
          {article.sections.map((section: Section, index: number) => (
            <React.Fragment key={index}>
              {renderSection(section)}
            </React.Fragment>
          ))}

          <div className='ad-container'>
            <ins className="adsbygoogle"
                style={{ display: 'block', textAlign: 'center' }}
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-3150152879069757"
                data-ad-slot="7223306595"></ins>
            <script>
              {`(adsbygoogle = window.adsbygoogle || []).push({});`}
            </script>
          </div>
        </div>

        <div className='article-links-section'>
          {relatedContents.length > 0 && (
            <div className="related-contents-section">
              <h2>Related Content</h2>
              <RelatedContentsList contents={relatedContents} />
            </div>
          )}
          {sources.length > 0 && (
            <div className="sources-section">
              <h2>Sources</h2>
              <SourceList 
                sources={sources} 
                totalCount={sources.length} 
                onExpand={() => {}} 
                showImage={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticlePage;
