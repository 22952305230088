// src/utils/countryUtils.ts
import iso3166 from 'iso-3166-1-alpha-2';

export function getCountryName(code: string): string {
  return iso3166.getCountry(code) || code;
}

export function getCountryCode(country: string): string {
  const codes = iso3166.getCodes();
  for (const code of codes) {
    if (iso3166.getCountry(code)?.toLowerCase() === country.toLowerCase()) {
      return code;
    }
  }
  return country;
}
