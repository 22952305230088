// src/components/TopNews.tsx
import React from 'react';
import { Article } from '../types/Articles';
import { Source } from '../types/Sources';
import ArticleList from './ArticleList';
import SourceList from './SourceList';
import ExpandLine from './ExpandLine';
import { useNavigate } from 'react-router-dom';
import { navigateToCategory } from '../utils/articleUtils';
import '../styles/TopNews.css';

export type TopNewsItem = 
  | { type: 'article'; content: Article & { showTimestamp: boolean }; }
  | { type: 'source'; content: Source; }
  | { type: 'header'; content: string }
  | { type: 'expandLine'; content: string; category: string; };

interface TopNewsProps {
  itemSets: Array<{
    title?: string;
    items: TopNewsItem[];
    totalCount: number;
    showExpand?: boolean;
  }>;
  onExpand: (setTitle: string) => void;
  isBreaking: boolean;
  useExpandLine?: boolean;
  expandNavigateTo?: string; // New prop for navigation link
  showSourceImages?: boolean;
  layout?: 'list' | 'card';
}

const TopNews: React.FC<TopNewsProps> = ({
  itemSets,
  onExpand,
  isBreaking,
  useExpandLine = true,
  expandNavigateTo,
  showSourceImages = false,
  layout = 'list', // Default to 'list' layout
}) => {
  const navigate = useNavigate();

  const handleCategoryClick = (category: string) => {
    navigateToCategory(navigate, category);
  };

  const handleExpand = (setTitle: string) => {
    if (expandNavigateTo) {
      navigate(expandNavigateTo);
    } else {
      onExpand(setTitle);
    }
  };

  return (
    <>
      {itemSets.map((set, setIndex) => (
        <div className={layout === 'card' ? 'card-container' : 'flex-gap-medium'} key={setIndex}>
          {set.items.map((item, itemIndex) => {
            switch (item.type) {
              case 'header':
                return (
                  <div 
                    className='h1' 
                    key={itemIndex}
                    onClick={() => handleCategoryClick(item.content)}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.content}
                  </div>
                );
              case 'article':
                return <ArticleList 
                  key={itemIndex} 
                  articles={[item.content]}
                  showRelativeTime={item.content.showTimestamp}
                  totalCount={1}
                  onExpand={() => handleExpand(set.title || '')}
                  isBreaking={isBreaking}
                  showImage={true}
                />;
              case 'source':
                return <div className={layout === 'card' ? 'card-item' : ''} key={itemIndex}>
                  <SourceList
                    sources={[item.content]}
                    totalCount={1}
                    onExpand={() => handleExpand(set.title || '')}
                    showImage={showSourceImages}
                  />
                </div>;
              case 'expandLine':
                return useExpandLine ? (
                  <ExpandLine 
                    key={itemIndex} 
                    category={item.category} 
                    onClick={() => handleExpand(item.category)}
                  />
                ) : null;
              default:
                return null;
            }
          })}
          {set.showExpand && useExpandLine && (
            <ExpandLine 
              category={set.title || ''} 
              onClick={() => handleExpand(set.title || '')}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default TopNews;
