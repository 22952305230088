// src/components/FloatingWidget.tsx
import React from 'react';
import '../styles/FloatingWidget.css';

interface FloatingWidgetProps {
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  children: React.ReactNode; // For rendering any React node inside the widget
}

const FloatingWidget: React.FC<FloatingWidgetProps> = ({ position, children }) => {
  return (
    <div className={`floating-widget-wrapper ${position}`}>
      {children}
    </div>
  );
};

export default FloatingWidget;
