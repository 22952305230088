// src/pages/HomePage.tsx
import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import { Article } from '../types/Articles';
import ActivityIndicator from '../components/ActivityIndicator';
import '../styles/Headlines.css';
import HeadlinesSection from '../components/HeadlinesSection';
import TopNews, { TopNewsItem } from '../components/TopNews';
import ArticlesSection from '../components/ArticlesSection';

const EDITORIAL_LIMIT = 5;
const HEADLINES_SOURCE_LIMIT = 5;

const fetchEditorials = async () => {
  const articlesQuery = query(
    collection(db, 'articles'),
    where('status', '==', 'published'),
    where('genre', '==', 'editorial'),
    where('access', '==', 'public'),
    orderBy('publishedDate', 'desc')
  );
  const articlesSnapshot = await getDocs(articlesQuery);
  return articlesSnapshot.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
    publishedDate: doc.data().publishedDate,
    categories: doc.data().categories || [],
  } as Article));
};

const HomePage = () => {
  const [editorials, setEditorials] = useState<Article[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const fetchedEditorials = await fetchEditorials();
        setEditorials(fetchedEditorials);
      } catch (err) {
        console.error('Error fetching editorials:', err);
        setError('Failed to fetch editorials');
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  if (loading) return <div style={{ display: 'flex', justifyContent: 'center' }}><ActivityIndicator /></div>;
  if (error) return <div>{error}</div>;

  const editorialItems: TopNewsItem[] = editorials
    .slice(0, EDITORIAL_LIMIT)
    .map(article => ({ 
      type: 'article' as const, 
      content: { ...article, showTimestamp: true } 
    }));
    
  const handleExpand = () => {
    console.log('Expand Editorials');
  };

  return (
    <div className='main-container'>
      <HeadlinesSection />
      <ArticlesSection ignoreCountryFilter={true} />
    </div>
  );
};

export default HomePage;
