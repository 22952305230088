// src/components/ArticleItem.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Article, RelatedArticle } from '../types/Articles';
import CategoryList from './CategoryList';
import { getGenre, getRelativeTime } from '../utils/articleUtils';
import ArticleImage from './ArticleImage';
import { renderSpecialCharacters, useIsMobile } from '../utils/general';

interface ArticleItemProps {
  article: Article | RelatedArticle;
  showGenre?: boolean;
  showRelativeTime?: boolean;
  isBreaking?: boolean;
  showImage?: boolean;
  imageAboveTitle?: boolean;
  isCard?: boolean;
}

const ArticleItem: React.FC<ArticleItemProps> = ({
  article,
  showGenre = false,
  showRelativeTime = false,
  isBreaking = false,
  showImage = false,
  imageAboveTitle = false,
  isCard = false,
}) => {
  const isMobile = useIsMobile();

  const imageElement = showImage && 'image' in article && article.image && (
    <div className={`article-image ${isCard ? 'card-image' : ''}`}>
      <ArticleImage imageId={article.image} isThumbnail={!isCard} isMobile={isMobile} />
    </div>
  );

  const contentElement = (
    <div className={`article-content ${isCard ? 'card-content' : ''}`}>
      <div className='article-header'>
        {showGenre && <span className="article-genre">{getGenre(article.genre)}</span>}
        <small>
          {isBreaking ? (
            <>
              {moment(article.publishedDate).format('LL')}
              {showRelativeTime && `, ${getRelativeTime(new Date(article.publishedDate))}`}
            </>
          ) : (
            moment(article.publishedDate).format('LL')
          )}
        </small>
      </div>
      <div className={`${article.genre === 'editorial' ? 'article-editorial-header' : ''}`}>
        <Link key={article.id} to={`/article/${article.permalink}`} className="article-link h3">
          {renderSpecialCharacters(article.title)}
        </Link>
        {article.genre === 'editorial' && 'categories' in article && article.categories && (
          <CategoryList categories={article.categories} />
        )}
      </div>
      <div className='article-synopsis card'>{renderSpecialCharacters(article.synopsis)}</div>
    </div>
  );

  return (
    <div className={`article-item ${isCard ? 'card' : ''}`}>
      {isCard || imageAboveTitle ? (
        <>
          {imageElement}
          {contentElement}
        </>
      ) : (
        <>
          {contentElement}
          {imageElement}
        </>
      )}
    </div>
  );
};

export default ArticleItem;
