// src/components/TopTopics.tsx
import React, { useEffect, useState, useRef } from 'react';
import { Topic } from '../utils/topicUtils';
import ActivityIndicator from './ActivityIndicator';
import '../styles/HomePage.css';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, TooltipProps } from 'recharts';
import '../styles/TopTopics.css';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import moment from 'moment';

interface TopTopicsProps {
  title: string;
  days: number;
  limit: number;
  country?: string | null;
}

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'var(--main-background-color)', border: '1px solid var(--menu-border-color)', padding: '10px' }}>
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

// Custom hook for Intersection Observer
const useIntersectionObserver = (ref: React.RefObject<HTMLElement>, options: IntersectionObserverInit = {}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, options]);

  return isIntersecting;
};

const TopTopics: React.FC<TopTopicsProps> = ({ title, days, limit, country }) => {
  const [topics, setTopics] = useState<Topic[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [dataDate, setDataDate] = useState<string | null>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const isVisible = useIntersectionObserver(chartRef, { threshold: 0.1 });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        setLoading(true);
        const today = moment().format('YYYY-MM-DD');
        const docRef = doc(db, 'trends', today);
        let docSnap = await getDoc(docRef);

        // If today's data doesn't exist, try yesterday's data
        if (!docSnap.exists()) {
          const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
          docSnap = await getDoc(doc(db, 'trends', yesterday));
        }

        if (docSnap.exists()) {
          const data = docSnap.data();
          const topicsData = country ? data[country]?.topics : data.global?.topics;
          if (topicsData) {
            setTopics(topicsData.slice(0, limit));
            
            // Correctly handle Firestore timestamp
            const timestamp = data.global.timestamp;
            if (timestamp instanceof Timestamp) {
              setDataDate(moment(timestamp.toDate()).format('D MMMM YYYY, h:mm A'));
            } else if (timestamp && timestamp._seconds) {
              setDataDate(moment.unix(timestamp._seconds).format('D MMMM YYYY, h:mm A'));
            } else {
              setDataDate('Unknown date');
            }
          } else {
            setError('No topics data found for the specified country or global');
          }
        } else {
          setError('No data found for today or yesterday');
        }
      } catch (err: unknown) {
        console.error('Error fetching topics:', err);
        if (err instanceof Error) {
          setError(`Failed to fetch topics: ${err.message}`);
        } else {
          setError('Failed to fetch topics: Unknown error');
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchTopics();
  }, [days, limit, country]);
  
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ActivityIndicator />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const handleBarClick = (data: any, index: number) => {
    console.log('Bar clicked:', data);
    navigate({
      pathname: '/search',
      search: `?topic=${encodeURIComponent(data.name)}`
    });
  };

  return (
    <>
      <div className='h2'>{title}</div>
      {dataDate && <div className='data-date'>Data as of {dataDate}</div>}
      {topics.length > 0 ? (
        <div ref={chartRef} style={{ width: '100%', height: '400px' }}>
          {isVisible && (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={topics}
                layout="vertical"
                margin={{
                  top: 20, right: 30, left: 20, bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="var(--primary-color)" />
                <XAxis 
                  type="number"
                  allowDecimals={false}
                  allowDataOverflow={false}
                  domain={[0, 'auto']}
                  tickCount={5}
                  tick={{ fill: 'var(--primary-color)' }}
                />
                <YAxis 
                  type="category" 
                  dataKey="name" 
                  width={150}
                  interval={0}
                  allowDecimals={false}
                  allowDataOverflow={false}
                  tick={{ fill: 'var(--primary-color)' }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar 
                  dataKey="count" 
                  fill="var(--link-color)"
                  onClick={handleBarClick}
                  cursor="pointer"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      ) : (
        <div>No topics available</div>
      )}
    </>
  );
};

export default TopTopics;
