import React, { useState } from 'react';
import '../styles/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import config from '../utils/config';
import CountrySelector from './CountrySelector';
import { useModal } from '../contexts/ModalContext';
import SettingsForm from './SettingsForm';
import AuthForms from './AuthForms';
import { useAuth } from '../contexts/AuthContext';

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuType = config.menuType;
  const isDevelopment = process.env.NODE_ENV === 'development';
  const { authUser } = useAuth();
  const { showModal, hideModal } = useModal();
  const location = useLocation();

  const handleSettingsClick = () => {
    if (authUser) {
      showModal(
        <SettingsForm 
          onClose={() => hideModal()}
        />,
        'Settings'
      );
    } else {
      showModal(
        <AuthForms 
          onSuccess={(isNewAccount: boolean) => {
            hideModal();
            if (isNewAccount) {
              // Show SettingsForm only if the account is newly created
              showModal(
                <SettingsForm 
                  onClose={() => hideModal()}
                  initialSection="account"
                />,
                'User Preferences'
              );
            }
          }}
          onClose={() => hideModal()}
        />,
        'Login or Create Account'
      );
    }
  };

  const renderMenuItems = () => {
    // Only render menu items if location is available
    if (!location) return null;

    return (
      <>
        <Link className='header-menu-link' to='/search' onClick={() => setMenuOpen(false)}>Search</Link>
        <div className='header-menu-link' onClick={handleSettingsClick} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon='cog' />
        </div>
        {isDevelopment && (
          <>
            {/* Your development menu items */}
          </>
        )}
      </>
    );
  };

  return (
    <header>
      <div className='header-container'>
        <div className='header-title'>
          {location && (  // Wrap this in a condition
            <Link to='/' onClick={() => setMenuOpen(false)}>
              <img src={require('../images/cyberheadliners_title.png')} className='header-logo' alt="CyberHeadliners" />
            </Link>
          )}
          <CountrySelector />
        </div>
        <div className='header-options'>
          <div className='visible-on-laptop laptop-header-menu'>
            {renderMenuItems()}
          </div>
          {menuType === 'hamburger' && (
            <div className='visible-on-mobile'>
              <button className='header-menu-button' onClick={() => setMenuOpen(!menuOpen)}>
                <FontAwesomeIcon icon='bars' />
              </button>
            </div>
          )}
        </div>
      </div>
      <nav className={`mobile-header-menu ${menuOpen ? 'mobile-header-menu-open' : ''}`}>
        {renderMenuItems()}
      </nav>
    </header>
  );
};

export default Header;