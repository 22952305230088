// src/contexts/ProfileContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { UserProfile } from '../types/Users';
import { db } from '../utils/firebaseConfig';
import { useAuth } from './AuthContext'; // Import AuthContext to get the user's UID

interface ProfileContextType {
  profile: UserProfile | null;
  loadingProfile: boolean;
  fetchUserProfile: () => Promise<void>;
  updateProfile: (updatedFields: Partial<UserProfile>) => Promise<void>;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { authUser } = useAuth();
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [loadingProfile, setLoadingProfile] = useState<boolean>(false);

  // Fetch user profile from Firestore
  const fetchUserProfile = async () => {
    if (!authUser) return; // If the user is not authenticated, do nothing
    setLoadingProfile(true);

    try {
      const profileRef = doc(db, 'userProfiles', authUser.uid);
      const profileDoc = await getDoc(profileRef);

      if (profileDoc.exists()) {
        setProfile(profileDoc.data() as UserProfile);
      } else {
        // If no profile exists, create a default profile document
        const defaultProfile: UserProfile = {
          firstName: '',
          lastName: '',
          location: '',
          createdDate: Timestamp.fromDate(new Date()),
          status: 'active',
        };
        await setDoc(profileRef, defaultProfile);
        setProfile(defaultProfile);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    } finally {
      setLoadingProfile(false);
    }
  };

  // Update user profile in Firestore
  const updateProfile = async (updatedFields: Partial<UserProfile>) => {
    if (!authUser) return; // If the user is not authenticated, do nothing

    try {
      const profileRef = doc(db, 'userProfiles', authUser.uid);
      await updateDoc(profileRef, updatedFields);
      
      // Update the profile in state
      setProfile(prev => prev ? { ...prev, ...updatedFields } : null);
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (authUser) {
      fetchUserProfile();
    } else {
      setProfile(null); // Clear profile when user logs out
    }
  }, [authUser]);

  return (
    <ProfileContext.Provider value={{ profile, loadingProfile, fetchUserProfile, updateProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = (): ProfileContextType => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};
