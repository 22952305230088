// src/components/Alert.tsx
import React, { useState, useEffect, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parse from 'html-react-parser';
import '../styles/Alert.css';

interface AlertProps {
  message: string;  // Changed to string to ensure proper parsing
  type?: 'success' | 'alert' | 'warning' | 'info';
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({ message, type = 'info', onClose }) => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        onClose();
      }, 1000);
    }, 5000);    

    return () => clearTimeout(timer);
  }, [onClose]);

  const handleClose = () => {
    setFadeOut(true);
    setTimeout(onClose, 1000);
  };

  return (
    <div className={`alert-wrapper ${fadeOut ? 'fade-out' : ''}`}>
      <div className={`alert-container ${type}`}>
        <div className='alert-message-container'>{parse(message)}</div>
        <div onClick={handleClose} className='alert-button-container icon'>
          <FontAwesomeIcon icon='xmark' />
        </div>
      </div>
    </div>
  );
};

export default Alert;
