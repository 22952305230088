// src/components/SettingsSections/NotificationsSection.tsx
import React, { useState } from 'react';
import { UserProfile } from '../../types/Users';

interface NotificationsSectionProps {
  user: UserProfile | null; // Accept null
  onSave: (data: Partial<UserProfile>) => void;
}

const NotificationsSection: React.FC<NotificationsSectionProps> = ({ user, onSave }) => {

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <div>Notifications Section (To be implemented)</div>
    </form>
  );
};

export default NotificationsSection;
