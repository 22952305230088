// src/components/MessageBox.tsx
import React from 'react';
import parse from 'html-react-parser';
import '../styles/MessageBox.css';

interface PromptProps {
  message: string;
  options: Array<{ label: string; action: () => void }>;
}

const MessageBox: React.FC<PromptProps> = ({ message, options }) => {
  return (
    <div className='prompt-wrapper'>
      <div className='prompt-container'>
        <div className='prompt-message-container'>{parse(message)}</div>
        <div className='prompt-button-container'>
          {options.map((option, index) => (
            <button key={index} onClick={option.action}>
              {option.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MessageBox;
