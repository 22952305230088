// src/components/TopicList.tsx
import React, { useEffect, useState } from 'react';
import '../styles/TopicsMap.css';
import { fetchCanonicalName } from '../utils/topicUtils';

interface TopicListProps {
  topics: string[]; // Array of objectIDs
  onTopicClick: (objectID: string) => void;
}

const TopicList: React.FC<TopicListProps> = ({ topics, onTopicClick }) => {
  const [canonicalNames, setCanonicalNames] = useState<string[]>([]);

  useEffect(() => {
    const fetchCanonicalNames = async () => {
      const names = await Promise.all(
        topics.map(async (topic) => {
          const name = await fetchCanonicalName(topic);
          return name || topic; // Fallback to objectID if no canonicalName found
        })
      );
      setCanonicalNames(names);
    };

    fetchCanonicalNames();
  }, [topics]);

  if (!canonicalNames.length) return null;

  return (
    <div className="topics-map">
      {canonicalNames.map((canonicalName, index) => (
        <span
          key={topics[index]}
          className="topic-item smaller"
          onClick={() => onTopicClick(topics[index])}
        >
          {canonicalName}
        </span>
      ))}
    </div>
  );
};

export default TopicList;
