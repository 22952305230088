// src/pages/HeadlinesPage.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Source } from '../types/Sources';
import FeatureSource from '../components/FeatureSource';
import { fetchArticleByObjectID, fetchContentFromAlgolia } from '../utils/searchUtils';
import ActivityIndicator from '../components/ActivityIndicator';
import TopTopics from '../components/TopTopics';
import '../styles/app.css';
import { scrollToTop } from '../utils/general';
import Flag from 'react-world-flags';
import { useCountry } from '../contexts/CountryContext';
import { getCountryCode } from '../utils/countryUtils';
import { Article } from '../types/Articles';
import HeadlinesList from '../components/HeadlinesList';
import ArticleCarousel from '../components/ArticleCarousel';

const ITEMS_PER_PAGE = 10;

const HeadlinesPage: React.FC = () => {
  const [headlines, setHeadlines] = useState<Source[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [countryFilter, setCountryFilter] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCountry, setSelectedCountry } = useCountry();
  
  const fetchHeadlines = useCallback(async (page: number, country: string | null): Promise<{ newHeadlines: Source[], hasMore: boolean }> => {
    const topics = country ? [country] : [];
    const { headlines, nbPages } = await fetchContentFromAlgolia(page, ITEMS_PER_PAGE, 'news', topics);
    return { 
      newHeadlines: headlines.map(item => item.content as Source), 
      hasMore: page < nbPages - 1 
    };
  }, []);

  useEffect(() => {
    const loadHeadlines = async () => {
      setLoading(true);
      const searchParams = new URLSearchParams(location.search);
      let country = searchParams.get('country');
  
      if (country) {
        if (country !== selectedCountry) {
          setSelectedCountry(country);
        }
      } else {
        country = null; // Set to null if not in URL, regardless of selectedCountry
      }
  
      setCountryFilter(country);
  
      try {
        const { newHeadlines, hasMore } = await fetchHeadlines(0, country);
        setHeadlines(newHeadlines);
        setHasMore(hasMore);
        setCurrentPage(0);
      } catch (error) {
        console.error('Error fetching headlines:', error);
      } finally {
        setLoading(false);
      }
    };
  
    loadHeadlines();
    scrollToTop();
  }, [location.search, fetchHeadlines, selectedCountry, setSelectedCountry]);

  const countryCode = countryFilter ? getCountryCode(countryFilter) : null;

  const handleLoadMore = async () => {
    if (loading || !hasMore) return;
  
    setLoading(true);
    try {
      const { newHeadlines, hasMore: moreAvailable } = await fetchHeadlines(currentPage + 1, countryFilter);
      setHeadlines(prevHeadlines => [...prevHeadlines, ...newHeadlines]);
      setHasMore(moreAvailable);
      setCurrentPage(prevPage => prevPage + 1);
    } catch (error) {
      console.error('Error loading more headlines:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTopicClick = (topic: string) => {
    navigate(`/search?topic=${encodeURIComponent(topic)}`);
  };

  if (headlines.length === 0 && !loading) {
    return <div className="main-container"><ActivityIndicator /></div>;
  }

  return (
    <div className="main-container">
      <div className="headlines-page flex-gap-large">
      <div className='page-header'>
          {countryFilter ? `${countryFilter} Headlines` : 'Headlines'}
          {countryFilter && countryCode && (
            <Flag 
              code={countryCode} 
              height="24" 
              width="36" 
              style={{ marginLeft: '1.5rem', verticalAlign: 'middle' }} 
            />
          )}
        </div>
        {headlines.length > 0 && (
          <div className="sections-container">
            <div className='section-container'>
              <FeatureSource source={headlines[0]} />
            </div>
            <div className='section-container'>
              <TopTopics title='Trending for Past 30 Days' days={30} limit={20} country={countryFilter} />
              {/* <ArticleCarousel country={countryFilter} /> */}
            </div>
          </div>
        )}
        <div className="headlines-list flex-gap-large">
          <HeadlinesList headlines={headlines.slice(1)} onTopicClick={handleTopicClick} />
        </div>
        {hasMore && (
          <div className='load-more-container'>
            {loading ? <ActivityIndicator /> : (
              <button 
                onClick={handleLoadMore} 
                disabled={loading}
                className="load-more-button expand-line"
              >
                Load More
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeadlinesPage;
