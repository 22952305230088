// src/utils/userUtils.ts
import { doc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import { UserProfile } from '../types/Users';
import { getAuth, updateEmail as updateAuthEmail } from 'firebase/auth';

// Define the EmailSendInfo type for the utility functions
interface EmailSendInfo {
  count: number;
  cooldownUntil: number;
  lastSendTime: number;
}

// Cooldown and email sending logic
let isSendingEmail = false; // Track the email sending status

export const canSendVerificationEmail = (emailSendInfo: EmailSendInfo, DAILY_SEND_LIMIT: number): boolean => {
  return emailSendInfo.count < DAILY_SEND_LIMIT && getRemainingCooldownTime(emailSendInfo) === 0;
};

export const getRemainingCooldownTime = (emailSendInfo: EmailSendInfo): number => {
  const now = Date.now();
  if (now < emailSendInfo.cooldownUntil) {
    return Math.ceil((emailSendInfo.cooldownUntil - now) / 1000);
  }
  return 0;
};

export const sendVerificationEmail = async (email: string): Promise<void> => {
  isSendingEmail = true;
  try {
    const response = await fetch(`https://${process.env.REACT_APP_REGION}-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendNewAccountVerification`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error('Failed to send verification email');
    }
  } catch (error) {
    console.error('Failed to send verification email:', error);
    throw error;
  } finally {
    isSendingEmail = false;
  }
};

export const updateUserProfile = async (uid: string, data: Partial<UserProfile>) => {
  try {
    const userRef = doc(db, 'userProfiles', uid);
    await setDoc(userRef, data, { merge: true });
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

export const initiateEmailChange = async (uid: string, currentEmail: string, newEmail: string) => {
  try {
    const response = await fetch(`https://${process.env.REACT_APP_REGION}-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendEmailChangeVerification`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ currentEmail, newEmail, uid }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `Failed to send verification email. Status: ${response.status}`);
    }

    const result = await response.json();
    return result.message;
  } catch (error) {
    console.error('Error initiating email change:', error);
    throw error;
  }
};

export const completeEmailChange = async (userId: string, newEmail: string) => {
  const auth = getAuth();
  if (!auth.currentUser) throw new Error("No authenticated user found");

  if (!auth.currentUser.emailVerified) {
    throw new Error("Please verify your email before completing the change");
  }

  // Update email in Firebase Auth
  await updateAuthEmail(auth.currentUser, newEmail);

  return newEmail;
};

export const validateEmail = (email: string): boolean | string => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return "Please enter a valid email address";
  }
  return true;
};
