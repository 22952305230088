// src/components/HeadlinesList.tsx
import React, { useState, useEffect } from 'react';
import SearchResultItem from './SearchResultItem';
import { fetchTaggedContents, fetchArticleByObjectID } from '../utils/searchUtils';
import { Source } from '../types/Sources';
import { Article } from '../types/Articles';

interface TaggedContent {
  objectIDs: string[];
  status: string;
}

type TaggedContents = Record<string, TaggedContent>;

interface HeadlinesListProps {
  headlines: Source[];
  onTopicClick: (topic: string) => void;
}

const HeadlinesList: React.FC<HeadlinesListProps> = ({ headlines, onTopicClick }) => {
  const [taggedContents, setTaggedContents] = useState<TaggedContents>({});
  const [mergedHeadlines, setMergedHeadlines] = useState<(Source | Article)[]>([]);

  useEffect(() => {
    const fetchTaggings = async () => {
      const taggings = await fetchTaggedContents();
      setTaggedContents(taggings);
    };
    fetchTaggings();
  }, []);

  useEffect(() => {
    const mergeHeadlines = async () => {
      const merged: (Source | Article)[] = [];
      for (const headline of headlines) {
        merged.push(headline);
        const tagging = taggedContents[headline.id];
        if (tagging && tagging.status === 'active') {
          for (const objectID of tagging.objectIDs) {
            const taggedArticle = await fetchArticleByObjectID(objectID);
            if (taggedArticle) {
              merged.push(taggedArticle);
            }
          }
        }
      }
      setMergedHeadlines(merged);
    };
    mergeHeadlines();
  }, [headlines, taggedContents]);

  const getItemId = (item: Source | Article): string => {
    if ('id' in item) {
      return item.id;
    } else if ('objectID' in item) {
      return (item as { objectID: string }).objectID;
    }
    return '';
  };

  const isOriginalHeadline = (item: Source | Article): boolean => {
    return headlines.some(headline => getItemId(headline) === getItemId(item));
  };

  return (
    <div className="headlines-list flex-gap-large">
      {mergedHeadlines.map((item, index) => {
        const isInserted = !isOriginalHeadline(item);

        return (
          <SearchResultItem
            key={`${getItemId(item)}-${index}`}
            item={item}
            onTopicClick={onTopicClick}
            showImage={true}
            isInserted={isInserted}
          />
        );
      })}
    </div>
  );
};

export default HeadlinesList;
