// src/contexts/MainLayoutContext.tsx

import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import { useSwipeable } from 'react-swipeable';
import config from '../utils/config';

// You might need to adjust the props if you're passing specific props to Header or SideMenu
const MainLayoutContext: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const menuType = config.menuType;
  const [isSideMenuVisible, setIsSideMenuVisible] = React.useState(false);
  
  const toggleSideMenu = (open?: boolean) => setIsSideMenuVisible(open !== undefined ? open : !isSideMenuVisible);

  useEffect(() => {
    const headerElement = document.querySelector('.header-container');
    if (headerElement) {
      const height = headerElement.clientHeight;
      setHeaderHeight(height);
      document.documentElement.style.setProperty('--header-height', `${height}px`);
    }
  }, []);

  // Handlers for swipeable functionality
  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] < 40 && !isSideMenuVisible) {
        toggleSideMenu(true);
      }
    },
    onSwipedLeft: () => {
      if (isSideMenuVisible) {
        toggleSideMenu(false);
      }
    },
    trackMouse: true,
    delta: 10,
  });

  return (
    <div {...handlers} className='app-container'>
      <Header />
      <main className='app-main' style={{ marginTop: `${headerHeight}px` }}>
        {children}
      </main>
    </div>
  );
};

export default MainLayoutContext;
