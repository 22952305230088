// src/hooks/useArticleSelection.ts
import { useState } from 'react';
import { Article } from '../types/Articles';

const useArticleSelection = () => {
  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);

  const handleArticleClick = (article: Article) => {
    setSelectedArticles(prev => {
      const isArticleSelected = prev.some(a => a.id === article.id);
      if (isArticleSelected) {
        return prev.filter(a => a.id !== article.id);
      } else {
        return [...prev, article];
      }
    });
  };

  return { selectedArticles, setSelectedArticles, handleArticleClick };
};

export default useArticleSelection;
