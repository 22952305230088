// src/components/ChangeEmailField.tsx
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validateEmail, initiateEmailChange } from '../utils/userUtils'; // Updated import to use initiateEmailChange
import { useAuth } from '../contexts/AuthContext';
import { useProfile } from '../contexts/ProfileContext';
import ActivityIndicator from './ActivityIndicator';
import { redact } from '../utils/general';

interface ChangeEmailFieldProps {
  currentEmail: string;
  onChangeEmail: (newEmail: string) => Promise<void>;
  disabled?: boolean;
}

const ChangeEmailField: React.FC<ChangeEmailFieldProps> = ({
  currentEmail,
  onChangeEmail,
  disabled,
}) => {
  const { authUser } = useAuth();
  const { profile } = useProfile();
  const [isEditing, setIsEditing] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!authUser) {
      return;
    }
  }, [authUser]);

  const handleStartChange = () => {
    setIsEditing(true);
    setNewEmail('');
    setError(null);
  };

  const handleSave = async () => {
    const validationResult = validateEmail(newEmail);
    if (typeof validationResult === 'string') {
      setError(validationResult);
      return;
    }

    if (newEmail === currentEmail) {
      setError('The new email is exactly the same as the current one.');
      return;
    }

    setIsLoading(true);
    try {
      // Use initiateEmailChange to send the verification email
      await initiateEmailChange(authUser!.uid, currentEmail, newEmail);
      setIsEditing(false);
      setError(null);
      alert('A verification email has been sent to your new email address. Please check your email to complete the change.');
    } catch (error) {
      setError('Failed to send verification email. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="change-email-field flex-gap-row flex-gap-small">
      {isLoading ? (
        <div className='center-align full'>
          <ActivityIndicator />
        </div>
      ) : isEditing ? (
        <>
          <input 
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder="Enter your new email address"
          />
          <FontAwesomeIcon 
            icon="check" 
            className="icon-small link" 
            onClick={handleSave} 
          />
          <FontAwesomeIcon 
            icon="xmark" 
            className="icon-small link" 
            onClick={() => setIsEditing(false)} 
          />
        </>
      ) : (
        <>
          <div style={{ flexGrow: 1 }}>{redact(currentEmail)}</div>
          <div>
            <button 
              className="secondary" 
              onClick={handleStartChange}
              disabled={disabled}
            >
              Change Email
            </button>
          </div>
        </>
      )}
      {error && <div className={`error-message ${error.includes('same') ? 'warning-message' : ''}`}>{error}</div>}
    </div>
  );
};

export default ChangeEmailField;
