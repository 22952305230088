// src/utils/general.ts
import { useEffect, useState } from "react";

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    window.addEventListener('resize', handleResize);
    handleResize();
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export const useIsMobile = (breakpoint = 768) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= breakpoint);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= breakpoint);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint]);

  return isMobile;
};

const htmlEntities: Record<string, string> = {
  '&rsquo;': '’',
  '&lsquo;': '‘',
  // Add more named entities as needed
  // '&amp;': '&',
  // '&lt;': '<',
  // '&gt;': '>',
  // '&quot;': '"',
  // '&apos;': "'",
  // Add more named entities as needed
};

// Function to decode HTML entities (both named and numeric)
export const renderSpecialCharacters = (text: string): string => {
  // Replace named entities first
  text = text.replace(/&[a-zA-Z]+;/g, (match) => {
    return htmlEntities[match] || match; // Use the map or return the original if not found
  });

  // Replace numeric entities
  return text.replace(/&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(parseInt(dec, 10));
  });
};

export function normalizeString(str: string): string {
  return str
    .toLowerCase()
    .replace(/^(the|a|an) /, '')
    .replace(/[^\w\s]/g, '')
    .trim();
}

export const redact = (email: string): string => {
  const [username, domain] = email.split('@');
  const [domainName, topLevelDomain] = domain.split('.');
  
  const redactedUsername = username.slice(0, 2) + '*'.repeat(Math.max(username.length - 2, 3));
  const redactedDomainName = domainName.slice(0, 1) + '*'.repeat(Math.max(domainName.length - 1, 3));
  
  return `${redactedUsername}@${redactedDomainName}.${topLevelDomain}`;
};
