// src/App.tsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LayoutContextProvider } from './contexts/LayoutContext';
import ArticleWriter from './pages/ArticleWriter';
import './styles/index.css';
import './utils/icons';
import LoginPage from './pages/LoginPage';
import MainLayoutContext from './contexts/MainLayoutContext';
import { UserProvider } from './contexts/UserContext';
import { AuthProvider } from './contexts/AuthContext';
import { ProfileProvider } from './contexts/ProfileContext';
import { AlertProvider } from './contexts/AlertContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import { ModalProvider } from './contexts/ModalContext';
import { SideMenuProvider } from './contexts/SideMenuContext';
import { FloatingWidgetProvider } from './contexts/FloatingWidgetContext';
import ArticlePage from './pages/ArticlePage';
import ArticlesAdminPage from './pages/ArticlesAdminPage';
import { MessageBoxProvider } from './contexts/MessageBoxContext';
import { CountryProvider } from './contexts/CountryContext';
import HomePage from './pages/HomePage';
import SourcesAdminPage from './pages/SourcesAdminPage';
import UnprocessedSourcesAdminPage from './pages/UnprocessedSourcesAdminPage';
import SearchPage from './pages/SearchPage';
import HeadlinesPage from './pages/HeadlinesPage';
import { TopicDisplayProvider } from './contexts/TopicDisplayContext';
import ConfirmNewAccountVerificationPage from './pages/ConfirmNewAccountVerificationPage';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './utils/firebaseConfig';
import ActivityIndicator from './components/ActivityIndicator';
import { ReCaptchaProvider } from './contexts/ReCaptchaContext';
import ConfirmEmailChangePage from './pages/ConfirmEmailChangePage';

const App: React.FC = () => {
  const [reCaptchaKey, setReCaptchaKey] = useState<string | null>(null);
  const basename = process.env.NODE_ENV === 'production' 
    ? process.env.REACT_APP_BASENAME
    : '';

  useEffect(() => {
    const fetchReCaptchaKey = async () => {
      try {
        const configRef = doc(db, 'config', 'system');
        const configDoc = await getDoc(configRef);
        if (configDoc.exists()) {
          const data = configDoc.data();
          setReCaptchaKey(data.reCAPTCHA?.siteKey || null);
        }
      } catch (error) {
        console.error('Error fetching reCAPTCHA key:', error);
      }
    };

    fetchReCaptchaKey();
  }, []);

  if (!reCaptchaKey) {
    return <div><ActivityIndicator /></div>; // Or your preferred loading indicator
  }
  
  return (
    <Router basename={basename}>
      <ReCaptchaProvider>
        <LayoutContextProvider>
          <AuthProvider>
            <ProfileProvider>
              <UserProvider>
                <AlertProvider>
                  <MessageBoxProvider>
                    <ModalProvider>
                      <SideMenuProvider>
                        <FloatingWidgetProvider>
                          <CountryProvider>
                            <TopicDisplayProvider>
                              <Routes>
                                {/* Public routes */}
                                <Route path='/' element={<MainLayoutContext><HomePage /></MainLayoutContext>} />
                                <Route path='/search' element={<MainLayoutContext><SearchPage /></MainLayoutContext>} />
                                <Route path='/headlines' element={<MainLayoutContext><HeadlinesPage /></MainLayoutContext>} />
                                <Route path='/article/:id' element={<MainLayoutContext><ArticlePage /></MainLayoutContext>} />
                                <Route path="/article/*" element={<MainLayoutContext><ArticlePage /></MainLayoutContext>} />
                                
                                {/* Login route */}
                                <Route path='/login' element={<LoginPage />} />
                                
                                {/* Protected routes */}
                                <Route path='/articlewriter' element={<ProtectedRoute><MainLayoutContext><ArticleWriter /></MainLayoutContext></ProtectedRoute>} />
                                <Route path='/articlesadmin' element={<ProtectedRoute><MainLayoutContext><ArticlesAdminPage /></MainLayoutContext></ProtectedRoute>} />
                                <Route path='/sourcesadmin' element={<ProtectedRoute><MainLayoutContext><SourcesAdminPage /></MainLayoutContext></ProtectedRoute>} />
                                <Route path='/unprocessedsourcesadmin' element={<ProtectedRoute><MainLayoutContext><UnprocessedSourcesAdminPage /></MainLayoutContext></ProtectedRoute>} />
                                
                                {/* Email verification routes */}
                                <Route path='/verify-email' element={<MainLayoutContext><ConfirmNewAccountVerificationPage /></MainLayoutContext>} />
                                <Route path='/verify-email-change' element={<MainLayoutContext><ConfirmEmailChangePage /></MainLayoutContext>} />

                                {/* Catch-all route */}
                                <Route path="*" element={<MainLayoutContext><HomePage /></MainLayoutContext>} />
                              </Routes>
                            </TopicDisplayProvider>
                          </CountryProvider>
                        </FloatingWidgetProvider>
                      </SideMenuProvider>
                    </ModalProvider>
                  </MessageBoxProvider>
                </AlertProvider>
              </UserProvider>
            </ProfileProvider>
          </AuthProvider>
        </LayoutContextProvider>
      </ReCaptchaProvider>
    </Router>
  );
};

export default App;
