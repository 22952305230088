// src/components/SettingsSections/AccountSection.tsx
import React, { useState, useEffect } from 'react';
import { UserProfile } from '../../types/Users';
import { useAuth } from '../../contexts/AuthContext'; // Use AuthContext
import { useProfile } from '../../contexts/ProfileContext'; // Use ProfileContext
import ActivityIndicator from '../ActivityIndicator';
import AllCountriesSelector from '../AllCountriesSelector';
import ChangeEmailField from '../ChangeEmailField';
import { getAuth, updateEmail, sendEmailVerification } from 'firebase/auth';

interface AccountSectionProps {
  onSave: (data: Partial<UserProfile>) => void;
  onClose: () => void;
}

const AccountSection: React.FC<AccountSectionProps> = ({ onSave, onClose }) => {
  const { authUser, signOut } = useAuth(); // Use sendEmailVerification
  const { profile, updateProfile } = useProfile(); // Use ProfileContext
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [location, setLocation] = useState('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (profile) {
      setFirstName(profile.firstName || '');
      setLastName(profile.lastName || '');
      setLocation(profile.location || '');
    }
  }, [profile]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!authUser) return;

    setIsSaving(true);
    try {
      const updatedData = { firstName, lastName, location };
      await updateProfile(updatedData);
      onSave(updatedData);
    } catch (error) {
      console.error('Failed to update user profile:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleLogoutAndClose = () => {
    signOut(); // Use signOut from AuthContext
    onClose();
  };

  const handleCountryChange = (selectedCountry: string) => {
    setLocation(selectedCountry);
  };

  const handleEmailChange = async (newEmail: string) => {
    try {
      const auth = getAuth();
  
      // Ensure the user is logged in
      if (auth.currentUser) {
        // Update the user's email in Firebase Authentication
        await updateEmail(auth.currentUser, newEmail);
  
        // Send the verification email to the new email address
        await sendEmailVerification(auth.currentUser);
  
        alert('A verification email has been sent to your new email address.');
      } else {
        throw new Error('No authenticated user found.');
      }
    } catch (error) {
      console.error('Error changing and verifying email:', error);
    }
  };

  if (!authUser) {
    return <div>No user data available.</div>;
  }

  return (
    <>
      <ChangeEmailField
        currentEmail={authUser.email || ''}
        onChangeEmail={handleEmailChange}
        disabled={isSaving}
      />
      <form onSubmit={handleSubmit} className="form-container">
        <input
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="First Name"
          disabled={!authUser.emailVerified || isSaving}
        />
        <input
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Last Name"
          disabled={!authUser.emailVerified || isSaving}
        />

        <AllCountriesSelector 
          value={location}
          onChange={handleCountryChange}
          disabled={!authUser.emailVerified || isSaving}
        />

        {isSaving ? (
          <div className='center-align'><ActivityIndicator /></div>
        ) : (
          <button className='secondary' type="submit" disabled={!authUser.emailVerified || isSaving}>Save Account Settings</button>
        )}
        <button className='secondary warning' onClick={handleLogoutAndClose} disabled={isSaving}>Logout</button>
      </form>
    </>
  );
};

export default AccountSection;
