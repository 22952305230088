// src/hooks/useSubjectsMap.ts
import { useEffect, useState } from 'react';
import { Article } from '../types/Articles';

interface SubjectInfo {
  count: number;
  articles: Article[];
}

interface SubjectsMap {
  [subject: string]: SubjectInfo;
}

const useSubjectsMap = (articles: Article[]): SubjectsMap => {
  const [subjectsMap, setSubjectsMap] = useState<SubjectsMap>({});

  useEffect(() => {
    if (articles && articles.length > 0) {
      const map: SubjectsMap = {};
      articles.forEach(article => {
        if (article.subjects) {
          article.subjects.forEach(subject => {
            if (!map[subject]) {
              map[subject] = { count: 0, articles: [] };
            }
            map[subject].count += 1;
            map[subject].articles.push(article);
          });
        }
      });
      setSubjectsMap(map);
    }
  }, [articles]);

  return subjectsMap;
};

export default useSubjectsMap;
