// src/components/CategoryList.tsx
import React from 'react';
import { getCategoryRenderValue } from '../utils/articleUtils';

interface CategoryListProps {
  categories: string[];
  className?: string;
}

const CategoryList: React.FC<CategoryListProps> = ({ categories, className = '' }) => {
  if (!categories || categories.length === 0) return null;

  return (
    <div className={`article-categories ${className} flex-gap-row flex-gap-tiny`}>
      {categories.map((category, index) => (
        <React.Fragment key={category}>
          <span className="article-category">{getCategoryRenderValue(category)}</span>
        </React.Fragment>
      ))}
    </div>
  );
};

export default CategoryList;
