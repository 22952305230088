// src/utils/generateArticlePromptTemplate.ts
export const promptTemplate = `
  Write a {{writeType}} article. Note that your article is not a mere summary of the following synopses but a fully written {{writeType}} article around {{writeTopic}} today. Write in a {{writeStyle}} way.
  Please use meaningful section headers in relation to their paragraphs and do not use generic headers like 'Introduction' or 'Conclusion'. For each section, write varying numbers of paragraphs. Some sections might have one paragraph, while others might have three or more. The number of paragraphs should naturally fit the content.
  Output the article in JSON format as follows:
  {
    "title": "Title here.",
    "synopsis": "Synopsis here.",
    "sections": [
      {
        "header": "First section header here",
        "paragraphs": [
          "Content of the first paragraph here.",
          "Content of the second paragraph here.",
          "Content of the third paragraph here.",
          "Content of the fourth paragraph here."
        ]
      },
      {
        "header": "Second section header here",
        "paragraphs": [
          "Content of the first paragraph here.",
          "Content of the second paragraph here.",
          "Content of the third paragraph here."
        ]
      },
      {
        "header": "Third section header here",
        "paragraphs": [
          "Content of the first paragraph here.",
          "Content of the second paragraph here.",
          "Content of the third paragraph here.",
          "Content of the fourth paragraph here.",
          "Content of the fifth paragraph here."
        ]
      }
    ]
  }

  Ensure that each paragraph in the section's paragraphs is represented as an individual string in the array. Write the article based on the following synopses of articles:
  {{writeReferences}}
`;