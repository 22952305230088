// src/styles/selectorStyle.ts
export const selectorStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: 'none',
    borderRadius: 0,
    borderBottom: `2px ${state.isFocused ? 'var(--link-color)' : 'var(--menu-border-color)'} solid`,
    backgroundColor: 'transparent',
    fontSize: '1rem',
    width: '100%',
    height: '100%',
    boxShadow: 'none',
    opacity: state.isDisabled ? 0.5 : 1,
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: 'var(--main-background-color)',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? 'var(--disabled-background-color)'
      : state.isSelected
      ? 'var(--highlight-background-color)'
      : state.isFocused
      ? 'var(--hover-background-color)'
      : 'var(--main-background-color)',
    color: state.isDisabled ? 'var(--disabled-color)' : 'var(--primary-color)',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    color: 'var(--primary-color)',
    height: '100%',
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: state.isDisabled ? 'var(--disabled-color)' : 'var(--primary-color)',
    margin: '0',
    position: 'static',
    transform: 'none',
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isDisabled ? 'var(--disabled-chip-background-color)' : 'var(--chip-background-color)',
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    color: state.isDisabled ? 'var(--disabled-chip-label-color)' : 'var(--chip-label-color)',
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: state.isDisabled ? 'var(--disabled-chip-remove-color)' : 'var(--chip-remove-color)',
    '&:hover': {
      backgroundColor: state.isDisabled ? 'transparent' : 'var(--chip-remove-background-hover)',
      color: state.isDisabled ? 'var(--disabled-chip-remove-color)' : 'var(--chip-remove-color-hover)',
    },
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    color: state.isDisabled ? 'var(--disabled-color)' : 'var(--primary-color)',
  })
};
