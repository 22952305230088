import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../utils/firebaseConfig';
import EmailVerification from './EmailVerification';
import { useAuth } from '../contexts/AuthContext';

interface LoginFormProps {
  onSuccess: () => void;
  onSwitchToCreate: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSuccess, onSwitchToCreate }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { authUser } = useAuth();

  useEffect(() => {
    if (authUser && authUser.emailVerified) {
      onSuccess();
    }
  }, [authUser, onSuccess]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      // The UserContext will automatically update with the new user info
    } catch (error) {
      setError('Failed to log in. Please check your email and password.');
    }
  };

  if (authUser && !authUser.emailVerified) {
    return <EmailVerification onVerified={onSuccess} />;
  }

  return (
    <form onSubmit={handleLogin} className='form-container'>
      <div className='h4'>Login</div>
      <input
        type="email"
        placeholder="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      {error && <div className="error-message">{error}</div>}
      <button type="submit">Login</button>
      <div className='link' onClick={onSwitchToCreate}>Create a new account</div>
    </form>
  );
};

export default LoginForm;
