// src/components/ArticlesSection.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { Article } from '../types/Articles';
import { fetchContentFromAlgolia, getSearchConfig } from '../utils/searchUtils';
import { useIsMobile } from '../utils/general';
import ActivityIndicator from './ActivityIndicator';
import { useCountry } from '../contexts/CountryContext';
import ArticleItem from './ArticleItem';
import '../styles/ArticlesSection.css';

interface ArticlesSectionProps {
  ignoreCountryFilter?: boolean;
}

const ArticlesSection: React.FC<ArticlesSectionProps> = ({ ignoreCountryFilter = false }) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const isMobile = useIsMobile();
  const { selectedCountry = '' } = useCountry();

  const fetchArticles = useCallback(async (page: number) => {
    try {
      const config = await getSearchConfig();
      const limit = config.articleHomePageLimit || 10;
      setItemsPerPage(limit);
      
      let topics = ignoreCountryFilter ? [] : (selectedCountry ? [selectedCountry] : []);
      
      const { headlines, nbPages } = await fetchContentFromAlgolia(page, limit, 'article', topics);
      return { 
        newArticles: headlines.map(item => item.content as Article), 
        hasMore: page < nbPages - 1 
      };
    } catch (error) {
      console.error('Error fetching articles:', error);
      throw error;
    }
  }, [selectedCountry, ignoreCountryFilter]);

  useEffect(() => {
    const loadArticles = async () => {
      setLoading(true);
      try {
        const { newArticles, hasMore } = await fetchArticles(0);
        setArticles(newArticles);
        setHasMore(hasMore);
        setCurrentPage(0);
      } catch (error) {
        setError('Failed to fetch articles. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadArticles();
  }, [fetchArticles]);

  const handleLoadMore = async () => {
    if (loading || !hasMore) return;
  
    setLoading(true);
    try {
      const { newArticles, hasMore: moreAvailable } = await fetchArticles(currentPage + 1);
      setArticles(prevArticles => [...prevArticles, ...newArticles]);
      setHasMore(moreAvailable);
      setCurrentPage(prevPage => prevPage + 1);
    } catch (error) {
      setError('Failed to load more articles. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="articles-section">
      <span className='home-section-header link-pointer' onClick={() => {}}>Articles</span>
      {loading && articles.length === 0 ? (
        <ActivityIndicator />
      ) : (
        <div className={`articles-grid ${isMobile ? 'mobile-view' : 'desktop-view'}`}>
          {articles.map(article => (
            <ArticleItem
              key={article.id}
              article={article}
              showGenre={true}
              showRelativeTime={true}
              showImage={true}
              imageAboveTitle={true}
              isCard={!isMobile}
            />
          ))}
        </div>
      )}
      {hasMore && (
        <div className='load-more-container'>
          {loading ? <ActivityIndicator /> : (
            <button 
              onClick={handleLoadMore} 
              disabled={loading}
              className="load-more-button expand-line"
            >
              Load More
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ArticlesSection;
