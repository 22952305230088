// src/pages/SearchPage.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import { Article } from '../types/Articles';
import { Source } from '../types/Sources';
import { SearchFilters } from '../types/Search';
import SearchResultItem from '../components/SearchResultItem';
import TopicSelector from '../components/TopicSelector';
import '../styles/SearchPage.css';
import '../styles/ArticlePage.css';
import { getSearchConfig, performSearch, SearchConfig, loadMoreResults, transformAlgoliaHit } from '../utils/searchUtils';
import ActivityIndicator from '../components/ActivityIndicator';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router-dom';
import { handleTopicClick } from '../utils/topicUtils';

const ITEMS_PER_PAGE = 10;

const DateRangePickerWrapper: React.FC<{
  startDate: number | undefined;
  endDate: number | undefined;
  onChange: (dates: [number | undefined, number | undefined]) => void;
}> = ({ startDate, endDate, onChange }) => (
  <DatePicker
    selectsRange={true}
    startDate={startDate ? new Date(startDate) : undefined}
    endDate={endDate ? new Date(endDate) : undefined}
    onChange={(update: [Date | null, Date | null]) => {
      onChange([
        update[0] ? update[0].getTime() : undefined,
        update[1] ? update[1].getTime() : undefined
      ]);
    }}
    isClearable={true}
    placeholderText="Select date range"
    className='search-datepicker'
  />
);

const SearchControls: React.FC<{
  filters: SearchFilters;
  setFilters: React.Dispatch<React.SetStateAction<SearchFilters>>;
  allTopics: { value: string; label: string }[];
  nonExistentTopic: string | null;
}> = ({ filters, setFilters, allTopics, nonExistentTopic }) => {
  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(prev => ({ 
      ...prev, 
      searchTerm: event.target.value
    }));
  };

  const handleTopicsChange = (selectedTopics: string[]) => {
    setFilters(prev => ({ ...prev, topics: selectedTopics }));
  };

  return (
    <div className="search-controls sections-container">
      <div className='section-container'>
        <input
          type="text"
          value={filters.searchTerm}
          onChange={handleSearchTermChange}
          placeholder="Search..."
          className="search-input"
        />
      </div>
      <div className='section-container'>
        <DateRangePickerWrapper
          startDate={filters.dateRange[0]}
          endDate={filters.dateRange[1]}
          onChange={(dateRange) => setFilters(prev => ({ ...prev, dateRange }))}
        />
      </div>
      <div className='section-container'>
        <div className='topic-selector-wrapper'>
          <TopicSelector
            selectedTopics={filters.topics}
            onChange={handleTopicsChange}
          />
          {nonExistentTopic && (
            <div className="error-message">
              Topic "{nonExistentTopic}" does not exist
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SearchPage: React.FC = () => {
  const [filters, setFilters] = useState<SearchFilters>({
    contentType: 'all',
    searchTerm: '',
    dateRange: [undefined, undefined],
    topics: [],
  });
  const [results, setResults] = useState<(Article | Source)[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchConfig, setSearchConfig] = useState<SearchConfig | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [allTopics, setAllTopics] = useState<{ value: string; label: string }[]>([]);
  const [nonExistentTopic, setNonExistentTopic] = useState<string | null>(null);
  const location = useLocation();
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialDelayPassed, setInitialDelayPassed] = useState(false);

  document.title = 'Search | CyberHeadliners';

  useEffect(() => {
    getSearchConfig().then(config => {
      setSearchConfig(config);
      console.log("Search config set:", config);
    });
  }, []);

  useEffect(() => {
    const fetchAllTopics = async () => {
      try {
        const topicsCollection = collection(db, 'topics');
        const topicsSnapshot = await getDocs(topicsCollection);
        const fetchedTopics = topicsSnapshot.docs
          .map(doc => {
            const topicData = doc.data();
            if (topicData && topicData.topic && typeof topicData.topic === 'string') {
              return {
                value: topicData.topic,
                label: topicData.topic
              };
            }
            return null;
          })
          .filter((topic): topic is { value: string; label: string } => topic !== null);
        setAllTopics(fetchedTopics);
      } catch (error) {
        console.error("Error fetching topics:", error);
        setAllTopics([]);
      }
    };

    fetchAllTopics();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const topicFromUrl = searchParams.get('topic');
    if (topicFromUrl) {
      setFilters(prev => ({
        ...prev,
        topics: prev.topics.includes(topicFromUrl) ? prev.topics : [...prev.topics, topicFromUrl]
      }));
    }

    // Set a timeout to allow for URL processing
    const timer = setTimeout(() => {
      setInitialDelayPassed(true);
    }, 100); // 100ms delay, adjust if needed

    return () => clearTimeout(timer);
  }, [location.search]);

  const fetchResults = useCallback(async () => {
    if (!searchConfig) return;
  
    setLoading(true);
    setInitialLoading(true);
    try {
      const { hits, nbPages } = await performSearch({
        ...filters,
        dateRange: filters.dateRange as [number | undefined, number | undefined]
      }, searchConfig, 0, ITEMS_PER_PAGE);
        
      const transformedHits = hits.map(transformAlgoliaHit);
  
      setResults(transformedHits);
      setCurrentPage(0);
      setHasMore(nbPages > 1);
    } catch (error) {
      console.error('Error fetching results:', error);
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  }, [filters, searchConfig]);
  
  useEffect(() => {
    // Only fetch results if initial delay has passed or if filters change after initial delay
    if (initialDelayPassed) {
      fetchResults();
    }
  }, [filters, searchConfig, initialDelayPassed, fetchResults]);

  const handleLoadMore = async () => {
    if (!searchConfig) return;
  
    setLoading(true);
    try {
      const { newHits, hasMore } = await loadMoreResults({
        ...filters,
        dateRange: filters.dateRange as [number | undefined, number | undefined]
      }, searchConfig, currentPage + 1);
      
      const transformedNewHits = newHits.map(transformAlgoliaHit);
  
      setResults(prevResults => [...prevResults, ...transformedNewHits]);
      setCurrentPage(prevPage => prevPage + 1);
      setHasMore(hasMore);
    } catch (error) {
      console.error('Error loading more results:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTopicClickLocal = (topicId: string) => {
    if (!filters.topics.includes(topicId)) {
      handleTopicClick<SearchFilters>(topicId, setFilters, setNonExistentTopic);
    }
    // If the topic already exists, do nothing
  };
  
  return (
    <div className='main-container'>
      <div className="flex-gap-medium" style={{ position: 'relative' }}>
        <SearchControls 
          filters={filters}
          setFilters={setFilters}
          allTopics={allTopics}
          nonExistentTopic={nonExistentTopic}
        />
        {initialLoading ? (
          <div className="center-container full">
            <ActivityIndicator />
          </div>
        ) : (
          <div className="search-results flex-gap-large">
            {results.map(item => (
              <SearchResultItem key={item.id} item={item} onTopicClick={handleTopicClickLocal} showImage={false} />
            ))}
          </div>
        )}
        {!initialLoading && hasMore && (
          <div className='load-more-container'>
            <button 
              onClick={handleLoadMore} 
              disabled={loading}
              className="load-more-button expand-line"
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPage;
