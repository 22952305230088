import React from 'react';
import '../styles/ActivityIndicator.css'

const ActivityIndicator = () => {
  return (
    <div className='activity-indicator'></div>
  );
};

export default ActivityIndicator;
