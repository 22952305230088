// src/utils/icons.ts
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCoffee,
  faBars,
  faCaretLeft,
  faCaretRight,
  faXmark,
  faPenToSquare,
  faCheck,
  faCircleXmark,
  faTriangleExclamation,
  faCircleInfo,
  faWrench,
  faICursor,
  faTimeline,
  faCaretDown,
  faUserCircle,
  faCog,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';

// Add icons to the library
library.add(faCoffee);
library.add(faBars);
library.add(faCaretLeft);
library.add(faCaretRight);
library.add(faCaretDown);
library.add(faXmark);
library.add(faPenToSquare);
library.add(faCheck);
library.add(faCircleXmark);
library.add(faTriangleExclamation);
library.add(faCircleInfo);
library.add(faWrench);
library.add(faICursor);
library.add(faTimeline);
library.add(faUserCircle);
library.add(faCog);
library.add(faCaretUp);
